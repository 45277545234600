import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { History } from 'history';
import { ConnectedRouter as Router } from 'connected-react-router';
import Root from '@src/components/Root/Root';
import NoMatch from '@src/features/NoMatch/NoMatch';
import Duplicated from '@src/features/Duplicated';
import Test from '@src/features/Test';
import Lessons from '@src/features/Lessons';
import LessonDetails from '@src/features/LessonDetails';
import Checkout from '@src/features/Checkout';

interface Props {
  history: History;
}

const prefixRoute = '/:applicationName';

const Routes = ({ history }: Props) => (
  <Root history={history}>
    <Router history={history}>
      <Switch>
        <Route exact={true} path={`${prefixRoute}/`} component={Lessons} />
        <Route exact={true} path={`${prefixRoute}/lessons`} component={Lessons} />
        <Route exact={true} path={`${prefixRoute}/lessonDetails`} component={LessonDetails} />
        <Route exact={true} path={`${prefixRoute}/checkout`} component={Checkout} />
        <Route exact={true} path={`${prefixRoute}/duplicated`} component={Duplicated} />
        <Route exact={true} path={`${prefixRoute}/test`} component={Test} />
        <Route component={NoMatch} />
      </Switch>
    </Router>
  </Root>
);

export default Routes;
