/* eslint @typescript-eslint/no-explicit-any: off */
import api from './api';
import moment from 'moment';

export interface EnterprisePerson {
  personId: string;
  enterprisePersonId: string;
  firstName: string;
  lastName: string;
  dateOfBirth?: moment.Moment | string;
  homePhone?: string;
  email?: string;
  personRoleType?: string;
  isSelfSwitch?: boolean;
  isSelfDisabled?: boolean;
  currentStep?: number;
  isGuardianSwitch?: boolean;
}

export async function fetchEnterprisePersons(agencyGuid: string, enterprisePersonId: string) {
  const url = `/agencies/${agencyGuid}/families/${enterprisePersonId}`;
  const { data } = await api.get<EnterprisePerson[]>(url);
  return data;
}

export async function fetchEnterprisePersonsFromCCM(agencyGuid: string, email: string) {
  const encodeEmail = encodeURIComponent(email);
  const url = `/agencies/${agencyGuid}/families/invited?email=${encodeEmail}`;
  const { data } = await api.get<EnterprisePerson[]>(url);
  return data;
}
