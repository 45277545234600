import api from './api';

export interface UserProfile {
  email?: string;
  personId?: string;
  enterprisePersonId?: string;
  firstName?: string;
  lastName?: string;
  isNewUser?: boolean;
  isChangeLocalProfile?: boolean;
  signedWaiverIds?: Array<number | string>;
}
export interface Token {
  token: string;
}

export async function fetchUserProfile(agencyGuid: string) {
  const url = `/login/userProfile/${agencyGuid}`;
  const { data } = await api.get<UserProfile>(url);
  return data;
}

export async function fetchJwtToken() {
  const url = `/login`;
  const { data } = await api.get<Token>(url);
  return data;
}

export async function fetchAnonymousToken(email: string) {
  const url = '/token/anonymous';
  const { data } = await api.post<Token>(url, { email });
  return data;
}

export async function destroy() {
  const url = '/destroy';
  await api.delete(url);
}

export async function refreshToken() {
  const url = '/token/refresh';
  const { data } = await api.put<Token>(url);
  return data;
}
