import api from './api';

export interface WaiverDetails {
  id: string;
  name: string;
  agreementText: string;
  content: string;
  version: string;
  type: string;
}

interface WaiversList {
  waivers: WaiverDetails[];
}

export async function fetchWaivers(agencyGuid: string, lessonGuid: string) {
  const url = `/agencies/${agencyGuid}/waivers/${lessonGuid}`;
  const { data } = await api.get<WaiversList[]>(url);
  return data;
}
