import { useEffect, useState } from 'react';

const tabId = new Date().getTime();

export function useIsActiveTab(): boolean {
  const [isActiveTab, setIsActiveTab] = useState(true);
  useEffect(() => {
    setActiveTab();
    function updateIsActiveTab() {
      setIsActiveTab(checkIfActiveTab());
    }
    window.addEventListener('storage', updateIsActiveTab);
    return () => {
      window.removeEventListener('storage', updateIsActiveTab);
    };
  }, []);
  return isActiveTab;
}

function checkIfActiveTab(): boolean {
  const activeTab = localStorage.getItem('activeTab');
  if (!activeTab) {
    return true;
  }

  return isNaN(parseInt(activeTab, 10)) || tabId >= parseInt(activeTab, 10);
}

function setActiveTab(): void {
  localStorage.setItem('activeTab', tabId.toString());
}
