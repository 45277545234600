declare global {
  /*eslint-disable no-unused-vars*/
  interface Window {
    env: { [key: string]: string };
    DEPLOY_TARGET: 'GCP' | 'LV';
  }
}

const getConfigBasedOnEnv = (variable: string) => (env: { [key: string]: string }) =>
  process.env.NODE_ENV === 'production' ? env[variable] : process.env[`REACT_APP_${variable}`];

const getClientId = getConfigBasedOnEnv('CLIENT_ID');

const getResourceAPI = getConfigBasedOnEnv('RESOURCE_API');

const getDomain = getConfigBasedOnEnv('DOMAIN');
const getCheckoutDomain = getConfigBasedOnEnv('CHECKOUT_DOMAIN');
const getFormBuilderEnv = getConfigBasedOnEnv('FORM_BUILDER_ENV');

const config = {
  passport: {
    getClientId,
    locale: 'en_US',
    countryCodes: 'CN,UK',
  },
  getResourceAPI,
  getDomain,
  getCheckoutDomain,
  getFormBuilderEnv,
};

export { config, getConfigBasedOnEnv };
