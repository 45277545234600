/* eslint @typescript-eslint/explicit-module-boundary-types: off */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '@src/components/Spinner';
import { RootState } from '@src/app/rootReducer';
import NoMatch from '@src/features/NoMatch/NoMatch';
import { getAgencyDetail } from '@src/components/Agency/AgencySlice';
import { getAppearance } from '@src/components/Banner/BannerSlice';
import AlertMessage from '../AlertMessage';
import IdleMonitor from './IdleMonitor';
import Header from '../Header';
import Footer from '../Footer';
import './Root.scss';
import { History } from 'history';
import { setDuplicated } from '@src/features/Duplicated/DuplicatedSlice';
import { useIsActiveTab } from '@src/hooks/useIsActiveTab';
import { clearCartInfo } from '@src/features/LessonDetails/CartSlice';
import { RETIRED_CART } from '@src/utils/constants';

interface RootProps {
  children?: React.ReactElement[] | React.ReactElement;
  history: History;
}

const Root: React.FC<RootProps> = ({ children, history }: RootProps) => {
  const dispatch = useDispatch();
  const { pathname } = window.location;
  const applicationName = pathname.split('/').length > 1 ? pathname.split('/')[1] : '';
  const { error, isLoading, agencyInfo } = useSelector((state: RootState) => state.agency);
  const { isLogined } = useSelector((state: RootState) => state.login);
  const {
    multipleDaysTimeSlotListMap,
    reservationDetails: { orderId },
  } = useSelector((state: RootState) => state.cart);
  const cartCount = multipleDaysTimeSlotListMap?.reduce((acc, item) => acc + item.timeSlotRows.length, 0);
  const errorOrNoAgency = !!error || !applicationName;
  const [hideHeader, setHideHeader] = useState(false);
  const isActiveTab = useIsActiveTab();

  useEffect(() => {
    if (applicationName) {
      dispatch(getAgencyDetail(applicationName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    agencyInfo.agencyGuid && dispatch(getAppearance(agencyInfo.agencyGuid));
    const title = document.querySelector('title') as HTMLTitleElement;
    title.text = 'ACTIVE | ' + agencyInfo.name;
  }, [dispatch, agencyInfo]);

  useEffect(() => {
    if (!isActiveTab && agencyInfo.applicationName) {
      setHideHeader(true);
      dispatch(setDuplicated(true));
      history.push(`/${agencyInfo.applicationName}/duplicated`);
    }
  }, [isActiveTab, dispatch, agencyInfo.applicationName, history]);

  /**
   * handle special case, when open tab use "Duplicate Tab", will purge session storage for hide tab,
   * for the active tab we will purge the cart data from State.
   */
  useEffect(() => {
    const syncRetiredCart = () => {
      const retiredCart = Number(localStorage.getItem(RETIRED_CART));
      if (retiredCart && orderId === retiredCart) {
        dispatch(clearCartInfo());
        localStorage.removeItem(RETIRED_CART);
      }
    };
    window.addEventListener('storage', syncRetiredCart);
    return () => {
      window.removeEventListener('storage', syncRetiredCart);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showHomePage = !isLoading && !!agencyInfo.applicationName;
  return showHomePage ? (
    <div className='root-container'>
      <Header hideHeader={hideHeader} />
      <IdleMonitor isLogined={isLogined} startShopping={cartCount > 0} agencyGuid={agencyInfo?.agencyGuid} />
      <AlertMessage />
      <div className='content'>{children}</div>
      <Footer />
    </div>
  ) : isLoading && !errorOrNoAgency ? (
    <Spinner />
  ) : (
    <div className='root-container'>
      <Header />
      <div className='content'>
        <NoMatch />
      </div>
      <Footer />
    </div>
  );
};

export default Root;
