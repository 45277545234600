import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import useLocale from '@src/utils/useLocale';
import { RootState } from '@src/app/rootReducer';
import Modal from '@active/react-ui/Modal/Modal';
import { closeModal } from './AlertModalSlice';
import './AlertModal.scss';

interface AlertModalProps {
  customName?: string;
}

const AlertModal = ({ customName }: AlertModalProps) => {
  const { isOpen, title, content, contentParams = [], okText, onCustomClick, onCloseClick } = useSelector(
    (state: RootState) => state.alertModal,
  );
  const dispatch = useDispatch();
  const { i18n } = useLocale();
  const closeModalPopup = () => {
    dispatch(closeModal());
    onCloseClick && onCloseClick();
  };

  const onOkClick = () => {
    dispatch(closeModal());
    onCustomClick && onCustomClick();
  };

  useEffect(() => {
    if (document.body && document.body.style && !isOpen) {
      document.body.style.overflow = 'auto';
      document.body.style.paddingRight = '0';
    }
  }, [isOpen]);

  return (
    <>
      {isOpen ? (
        <Modal shown={isOpen} onClose={closeModalPopup} title={i18n(title)} className={`${customName} alert-modal`}>
          <div className='modal-body'>{i18n(content, contentParams)}</div>
          <div className='modal-footer'>
            <button className={clsx('button', 'button-strong')} onClick={onOkClick}>
              {i18n(okText)}
            </button>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default AlertModal;
