export function getMessageIcon(type: string) {
  switch (type) {
    case 'error':
      return 'ic-cross-r';
    case 'warning':
      return 'ic-warning';
    case 'success':
      return 'ic-check-r';
    case 'info':
      return 'ic-info-r';
    default:
      return 'ic-cross-r';
  }
}
