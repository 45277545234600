import { createSlice } from '@reduxjs/toolkit';

interface AlertModalState {
  isOpen: boolean;
  title: string;
  content: string;
  contentParams: [];
  okText: string;
  onCustomClick: () => void;
  onCloseClick: () => void;
}

const initialState: AlertModalState = {
  isOpen: false,
  title: '',
  content: '',
  contentParams: [],
  okText: '',
  onCustomClick: () => {},
  onCloseClick: () => {},
};

export const alertModalSlice = createSlice({
  name: 'alertModal',
  initialState,
  reducers: {
    openModal: (state, { payload }) => {
      state.isOpen = true;
      state.title = payload.title;
      state.content = payload.content;
      state.okText = payload.okText;
      state.contentParams = payload.contentParams;
      state.onCustomClick = payload.onCustomClick;
      state.onCloseClick = payload.onCloseClick;
    },
    closeModal: state => {
      state.isOpen = false;
      state.title = '';
      state.content = '';
      state.okText = '';
      state.contentParams = [];
      state.onCustomClick = () => {};
      state.onCloseClick = () => {};
    },
  },
});

export const { openModal, closeModal } = alertModalSlice.actions;

export default alertModalSlice.reducer;
