export const MAX_TIME_SLOT_LENGTH = 120;
// export const INTERVAL_META = 30 * 1000;
// export const KEEP_ALIVE_INTERVAL = INTERVAL_META; // 30 secs
// export const SESSION_TIME_OUT = 15 * 60 * 1000; //15 min
// export const LOGOUT_TIME_OUT = 30 * 60 * 1000; //30 min
// export const EXTEND_SESSION_INTERVAL = 10 * 60 * 1000; // 10 min
export const INTERVAL_META = 20 * 1000;
export const KEEP_ALIVE_INTERVAL = INTERVAL_META; // 30 secs
export const SESSION_TIME_OUT = 15 * 30 * 1000; //15 min
export const LOGOUT_TIME_OUT = 30 * 30 * 1000; //30 min
export const EXTEND_SESSION_INTERVAL = 10 * 30 * 1000; // 10 min

export const PERSON_ROLE_TYPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  CHILD: 'CHILD',
  PRIMARY_PARENT: 'PRIMARY_PARENT',
  SECONDARY_PARENT: 'SECONDARY_PARENT',
};
