import React, { useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import useLocale from '@src/utils/useLocale';
import { RootState } from '@src/app/rootReducer';
import LogoDesk from './images/logo.svg';
import PassportModal from '@src/components/PassportModal';
import Spinner from '@src/components/Spinner';
import { logout } from '@src/features/Login/LoginSlice';
import { makeStyles } from '@material-ui/core/styles';
import { getUserProfile } from '@src/features/Login/LoginSlice';
import './Header.scss';
import { removeSessionStorage } from '@src/utils/storageUtil';
import { CHECKOUT_PAGE_PATH } from '@src/utils/constants';

const useStyles = makeStyles(() => ({
  paper: {
    marginTop: '4px',
  },
}));
interface Props {
  hideHeader?: boolean;
}
const Header = (props: Props) => {
  const { hideHeader = false } = props;
  const {
    agencyInfo: { name },
  } = useSelector((state: RootState) => state.agency);
  const dispatch = useDispatch();
  const { i18n } = useLocale();
  const classes = useStyles();

  const [showPassportModal, setShowPassportModal] = React.useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const { isLogined, userProfile, isLoading } = useSelector((state: RootState) => state.login);
  const { location } = useSelector((state: RootState) => state.router);
  const slideRef = useRef<unknown>(null);
  const openPassportModal = useCallback(() => {
    setShowPassportModal(true);
  }, []);
  const closePassportModal = useCallback(() => {
    setShowPassportModal(false);
  }, []);
  const signOut = useCallback(() => {
    setOpen(false);
    dispatch(logout());
  }, [dispatch]);

  const [open, setOpen] = React.useState(false);
  const [isShowMenu, setShowMenu] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (anchorRef.current !== null) {
      if (prevOpen.current === false && open === true) {
        anchorRef.current.focus();
      } else {
        anchorRef.current.blur();
      }
    }

    prevOpen.current = open;
  }, [open]);

  const isMobile = useMediaQuery('only screen and (max-width: 767px)');

  const isTriggerScrollOnMobile =
    useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
    }) && isMobile;

  const handleMenuToggle = () => {
    setShowMenu(isShowMenu => {
      //stop body scroll to fix scroll through in mobile
      if (!isShowMenu) {
        document.body.style.top = `-${window.pageYOffset}px`;
        document.body.style.position = 'fixed';
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.position = 'static';
        document.body.style.overflow = 'auto';
        const scrollY = document.body.style.top;
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
      return !isShowMenu;
    });
  };

  React.useEffect(() => {
    setOpen(false);
    setShowPassportModal(false);
    setShowMenu(false);
    if (CHECKOUT_PAGE_PATH.test(location.pathname) || hideHeader) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
    if (isMobile && slideRef.current !== null) {
      document.body.style.position = 'static';
      document.body.style.overflow = 'auto';
      const scrollY = document.body.style.top;
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }, [hideHeader, isMobile, location]);

  React.useEffect(() => {
    if (name && sessionStorage.getItem('checkoutComplete') === 'true' && !CHECKOUT_PAGE_PATH.test(location.pathname)) {
      sessionStorage.removeItem('checkoutComplete');
      removeSessionStorage('preventCheckoutRefresh');
      dispatch(getUserProfile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, name]);

  const userNameInfo =
    userProfile.firstName && userProfile.lastName
      ? i18n('header.user.name.perfix', [`${userProfile.firstName}`, `${userProfile.lastName}`])
      : userProfile.email;

  const renderLoginInfo = () => {
    return isMobile ? (
      <Slide ref={slideRef} direction='right' in={isShowMenu} mountOnEnter unmountOnExit>
        <div className='global-header__login'>
          {showPassportModal && <PassportModal closeModal={closePassportModal} />}
          <div className='global-header__login-org-name'>{name}</div>
          <div className='global-header__login-account'>
            {!isLogined ? (
              <Button onClick={openPassportModal}>{i18n('header.sign.in')}</Button>
            ) : (
              <>
                <div className='global-header__login-account-greet'>{userNameInfo}</div>
                <Button onClick={signOut}>{i18n('header.sign.out')}</Button>
              </>
            )}
          </div>
          <ul className='global-header__login-action-list'>
            <li className='global-header__login-question'>
              <i className='ic-question-o' />
              <span>Help</span>
            </li>
            <li className='global-header__login-country'>
              <i className='ic-earth' />
              <span>English</span>
              <i className='ic-angle-right' />
            </li>
          </ul>
        </div>
      </Slide>
    ) : (
      showHeader && (
        <div className='global-header__login'>
          {isLoading && <Spinner showMask={true} />}
          {showPassportModal && <PassportModal closeModal={closePassportModal} />}
          <Button className='global-header__login__question'>
            <i className='ic-question-o' />
          </Button>
          <Button
            className='global-header__login__locale'
            startIcon={<i className='ic-earth' />}
            endIcon={<i className={'ic-triangle-down'} />}
          >
            English
          </Button>
          <div className='global-header__login--line' />
          <div className='global-header__login__account'>
            {!isLogined ? (
              <Button onClick={openPassportModal} className='global-header__login__account__sign-in'>
                {i18n('header.sign.in')}
              </Button>
            ) : (
              <div>
                <Button
                  className='global-header__login__account__user-name'
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup='true'
                  onClick={handleToggle}
                  startIcon={<i className='ic-people-r' />}
                  endIcon={<i className={open ? 'ic-triangle-up' : 'ic-triangle-down'} />}
                >
                  <span className='global-header__login__account__user-name__info' title={userNameInfo}>
                    {userNameInfo}
                  </span>
                </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement={'bottom-end'} transition disablePortal>
                  {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
                      <Paper className={classes.paper}>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList autoFocusItem={open} id='menu-list-grow'>
                            <MenuItem className={'global-header__login__account__sign-out'} onClick={signOut}>
                              {i18n('header.sign.out')}
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            )}
          </div>
        </div>
      )
    );
  };

  return (
    <div
      className={classnames('global-header', {
        'global-header--no-match': !name,
        'global-header--scrolling': isTriggerScrollOnMobile,
      })}
    >
      <header>
        {name && isMobile && showHeader && (
          <div className='global-header__menu-btn' onClick={handleMenuToggle}>
            <i className={isShowMenu ? 'ic-cross' : 'ic-menu'} />
          </div>
        )}
        <div className='global-header__org'>
          <div className='global-header__org__logo'>
            <img src={LogoDesk} alt='Active Logo' />
          </div>
          {name && (
            <div className='global-header__org__name'>
              <span title={name}>{name}</span>
            </div>
          )}
        </div>
        {name && renderLoginInfo()}
      </header>
      {isMobile && isLoading && <Spinner showMask={true} />}
    </div>
  );
};

export default Header;
