import { createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { push } from 'connected-react-router';
import { AppThunk } from '@src/app/store';
import { LessonDetails, fetchLessonDetails, TimeSlot, DailyReservationTimeSlotDetails } from '@src/api/fetchLessonDetails';
import { TIME_SLOT_FETCH_PAGE_SIZE } from '@src/utils/constants';
import { removeTimeSlotMap, removeResponseTimeSlot, DaysTimeSlotsMap, clearCartInfo } from './CartSlice';
interface LessonDetailState {
  lessonDetails: LessonDetails;
  isInfiniteLoading: boolean;
  timeSlots: TimeSlot[];
  error: string | null;
  pageIndex: number;
  instructorId: string;
  isLoading: boolean;
}

export interface TimeSlotIdentifier {
  timeRange: string;
  instructorProductId?: string;
}

export interface TimeSlotIdentifierMap {
  timeSlotIdentifier: string;
}

const initialState: LessonDetailState = {
  lessonDetails: {} as LessonDetails,
  isInfiniteLoading: false,
  timeSlots: [],
  error: null,
  pageIndex: 0,
  instructorId: 'ALL',
  isLoading: false,
};

function loadingFailed(state: LessonDetailState, { payload }: PayloadAction<string>) {
  state.error = payload;
  state.isLoading = false;
}

// Slice
const lessonDetailSlice = createSlice({
  name: 'lessons',
  initialState,
  reducers: {
    getLessonDetailsStart: state => {
      state.isLoading = true;
    },
    getLessonDetailsSuccess: (state, { payload }) => {
      state.lessonDetails = payload;
      state.error = null;
      state.timeSlots = [...payload.timeSlots.slice(0, TIME_SLOT_FETCH_PAGE_SIZE)];
      state.pageIndex = 0;
      state.isLoading = false;
    },
    getLessonDetailsFailed: loadingFailed,
    getBucketSlots: (state, { payload }) => {
      state.isInfiniteLoading = true;
      state.pageIndex = payload.pageIndex;
    },
    getBucketSlotsSuccess: (state, { payload }) => {
      state.timeSlots = payload;
      state.isInfiniteLoading = false;
    },
    resetLessonDetails: state => {
      state.lessonDetails = {} as LessonDetails;
      state.isInfiniteLoading = false;
      state.timeSlots = [];
      state.error = null;
      state.pageIndex = 0;
      state.instructorId = 'ALL';
    },
    setInstructorId: (state, { payload }) => {
      state.instructorId = payload;
    },
    updateCertainTimeSlotStatus: (state, { payload }) => {
      const currentTimeSlot = state.timeSlots.filter(ts => ts.identifier === payload.identifier)[0];
      if (currentTimeSlot) {
        currentTimeSlot.timeSlotStatus = payload.timeSlotStatus;
      }
    },
    updateTimeSlotSpots: (state, { payload }) => {
      const currentTimeSlot = state.timeSlots.filter(ts => ts.identifier === payload.identifier)[0];
      if (currentTimeSlot) {
        currentTimeSlot.spots = payload.spots;
      }
    },
  },
});

export const {
  getLessonDetailsStart,
  getLessonDetailsSuccess,
  getLessonDetailsFailed,
  getBucketSlots,
  getBucketSlotsSuccess,
  resetLessonDetails,
  setInstructorId,
  updateCertainTimeSlotStatus,
  updateTimeSlotSpots,
} = lessonDetailSlice.actions;

export default lessonDetailSlice.reducer;

// Actions
export const getLessonDetails = (): AppThunk => async (dispatch, getState) => {
  const {
    agency: {
      agencyInfo: { agencyGuid, applicationName },
    },
    system: { lesson },
    lessonDetails: { instructorId },
    cart: {
      multipleDaysTimeSlotListMap,
      reservationDetails: { dailyReservationTimeSlots = [] },
    },
  } = getState();

  try {
    if (!agencyGuid || !lesson) {
      dispatch(getLessonDetailsFailed('No lesson'));
    } else {
      const { lessonGuid, selectedDate } = lesson;
      const formatDate = format(selectedDate, 'yyyy-MM-dd');
      dispatch(getLessonDetailsStart());
      const lessonDetailsResponse = await fetchLessonDetails(agencyGuid, lessonGuid, formatDate, instructorId);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      lessonDetailsResponse?.timeSlots?.map(
        ts =>
          (ts.identifier = JSON.stringify({
            lessonDate: formatDate,
            productId: ts.productId,
            timeSlot: `${ts.startTime} - ${ts.endTime}`,
            instructorProductionId: ts.instructor?.productId,
          })),
      );

      syncReservedData(dispatch, lessonDetailsResponse, multipleDaysTimeSlotListMap, dailyReservationTimeSlots, formatDate, instructorId);
      dispatch(getLessonDetailsSuccess(lessonDetailsResponse));
    }
  } catch (err) {
    dispatch(getLessonDetailsFailed(err ? err.toString() : 'Error'));
    dispatch(clearCartInfo());
    dispatch(push(`/${applicationName}/lessons`));
  }
};

const syncReservedData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<any, any, any>,
  lessonDetailsResponse: LessonDetails,
  cartItems: DaysTimeSlotsMap[],
  reservedItems: DailyReservationTimeSlotDetails[],
  lessonDate: string,
  instructorId: string,
) => {
  const currentDayItemSlots = cartItems.filter(item => item.lessonDate === lessonDate)[0]?.timeSlotRows;
  const currentDayReservationTimeSlots = reservedItems.filter(item => item.lessonDate === lessonDate)[0]?.reservationTimeSlots;

  if (instructorId === 'ALL') {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    currentDayItemSlots?.forEach(st => {
      const filteredTimeSlot = lessonDetailsResponse?.timeSlots?.filter(timeSlot => timeSlot.identifier === st.timeSlotIdentifier)[0];
      const orderLineId = currentDayReservationTimeSlots?.filter(timeSlot => timeSlot.orderLineId === st.orderLineId)[0]?.orderLineId;

      if (filteredTimeSlot?.timeSlotStatus !== 'RESERVED') {
        dispatch(removeTimeSlotMap(filteredTimeSlot?.identifier));
        dispatch(removeResponseTimeSlot(orderLineId));
      }
    });
  } else {
    const currentInstructorRelatedTimeSlot = lessonDetailsResponse.timeSlots.filter(timeSlot => timeSlot.instructor.guid === instructorId);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    currentInstructorRelatedTimeSlot?.forEach(ts => {
      const timeSlotsInMaps = currentDayItemSlots?.filter(
        tsl => tsl.instructorGuid === ts.instructor.guid && tsl.timeSlotIdentifier === ts.identifier,
      );
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      timeSlotsInMaps &&
        timeSlotsInMaps?.length &&
        currentDayItemSlots?.forEach(tsl => {
          if (ts.timeSlotStatus !== 'RESERVED') {
            dispatch(removeTimeSlotMap(tsl.orderLineId));
            dispatch(removeResponseTimeSlot(tsl.orderLineId));
          }
        });
    });
  }
};

export const getBucketSlotsTrans = (pageIndex: number): AppThunk => async (dispatch, getState) => {
  const {
    lessonDetails: {
      lessonDetails: { timeSlots: allTimeSlots },
      timeSlots,
    },
  } = getState();

  if (timeSlots.length === allTimeSlots.length) {
    return;
  }
  dispatch(getBucketSlots({ pageIndex }));
  const loadSlots = await new Promise(resolve => {
    return setTimeout(() => {
      resolve(
        timeSlots.concat(
          allTimeSlots.slice(pageIndex * TIME_SLOT_FETCH_PAGE_SIZE, pageIndex * TIME_SLOT_FETCH_PAGE_SIZE + TIME_SLOT_FETCH_PAGE_SIZE),
        ),
      );
    }, 400);
  });

  dispatch(getBucketSlotsSuccess(loadSlots));
};
