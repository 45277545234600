/* eslint @typescript-eslint/no-explicit-any: off */
export const PHONE_INTERNATIONAL = /^(?=.{6,32}$)(\+?[\d().\- ]*\d[\d().\- ]*)$/;
export const PHONE_NORTH_AMERICA = /^(?:1[-. ]?)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$/;
export const PHONE_NORTH_AMERICA_PARTS_MATCHER = /^(1)?([2-9][0-8][0-9])?([2-9][0-9]{2})([0-9]{4})$/;
export const EMAIL_FORMAT = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const HTTP_URL = /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i;

export function isValidEmail(emailAddress: string) {
  return EMAIL_FORMAT.test(emailAddress);
}

export function isValidHttpUrl(currentValue: string) {
  return HTTP_URL.test(currentValue);
}

export function isValidNumber(currentValue: string) {
  return /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/.test(currentValue);
}

export function isValidPhoneNumber(currentValue: string) {
  if (PHONE_NORTH_AMERICA.test(currentValue)) {
    return true;
  }
  if (PHONE_INTERNATIONAL.test(currentValue)) {
    return true;
  }
  return false;
}

export function isNotEmpty(object: any) {
  let bool = false;
  if (object != null) {
    if (object instanceof Array) {
      bool = object.length > 0;
    } else if (object instanceof Date) {
      bool = !!object.getTime();
    } else if (object instanceof Map) {
      bool = object.size > 0;
    } else if (object instanceof Object && object.constructor === Object) {
      bool = Object.keys(object).length > 0;
    } else if (typeof object === 'string') {
      bool = !!object.trim();
    } else {
      bool = !!object;
    }
  }
  return bool;
}

export const requiredValidation = (value: string, msg: React.ReactNode | string) => {
  return isNotEmpty(value) ? Promise.resolve() : Promise.reject(msg);
};
