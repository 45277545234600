import api from './api';

export interface RegFormParam {
  orderId: number;
  formData: RegistrantFormData;
  loginEnterprisePersonId: string;
}

export interface Registrant {
  registrant: object;
  waiverAgreements: WaiverAgreement[];
}

export interface WaiverAgreement {
  agreementDate: string;
  waiverId: string;
  waiverVersion: string;
  signed: boolean;
}

export interface RegistrantFormData {
  orderId: number;
  lessonGuid: string;
  registrants: Registrant[];
  parent: object;
  agencyGuid: string;
}

export async function saveRegForm(regFormParam: RegFormParam, agencyGuid: string) {
  const url = `/agencies/${agencyGuid}/forms/formData`;
  await api.post<void>(url, regFormParam);
}
