/* eslint @typescript-eslint/no-explicit-any: off */
import api from './api';

export interface FormBuilderToken {
  refreshToken: String;
  jwtToken: String;
}

export async function getFormBuilderToken(agencyGuid: String) {
  const url = `/agencies/${agencyGuid}/formBuilder/auth/authenticate`;
  const { data } = await api.get<FormBuilderToken>(url);
  return data;
}

export async function refreshFormBuilderToken(agencyGuid: String, refreshToken: String) {
  const url = `/agencies/${agencyGuid}/formBuilder/auth/refreshToken`;
  const { data } = await api.post<FormBuilderToken>(url, { refreshToken });
  return data;
}
