import React from 'react';
import NoMatchDesk from './images/img-404.svg';
import './NoMatch.scss';
import useLocale from '@src/utils/useLocale';

const NoMatch = () => {
  const { i18n } = useLocale();
  return (
    <div className='no-match'>
      <img className='no-match__logo' src={NoMatchDesk} alt='NoMatch Logo' />
      <div className='no-match__info'>{i18n('no-match.page.info')}</div>
      <div className='no-match__message'>{i18n('no-match.page.message')}</div>
    </div>
  );
};

export default NoMatch;
