import api from './api';
import { ReservationResponse } from './fetchLessonDetails';

export interface CheckoutParam {
  widget: string;
  domain?: string;
  customizedData: string;
}
export interface CheckoutToken {
  widgetToken: string;
}

export async function getCheckoutToken(checkoutParam: CheckoutParam, agencyGuid: string) {
  const url = `/agencies/${agencyGuid}/checkout/token`;
  const { data } = await api.post<CheckoutToken>(url, checkoutParam);
  return data;
}

export async function removeCartItem(removeParam: {}, agencyGuid: string) {
  const url = `/agencies/${agencyGuid}/checkout/item`;
  const { data } = await api.put<ReservationResponse>(url, removeParam);
  return data;
}
