import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@src/app/store';
import { clearCart as cartClearApi } from '@src/api/fetchLessonDetails';
import { setLocalStorage } from '@src/utils/storageUtil';
import { RETIRED_CART } from '@src/utils/constants';
interface DuplicatedState {
  duplicated: boolean;
}
const initialState = {
  duplicated: false,
};
const duplicatedSlice = createSlice({
  name: 'duplicated',
  initialState,
  reducers: {
    setDuplicated: (state: DuplicatedState, { payload }: PayloadAction<boolean>) => {
      state.duplicated = payload;
    },
  },
});

export const { setDuplicated } = duplicatedSlice.actions;

export const clearReservedData = (checkoutComplete: boolean): AppThunk => async (dispatch, getState) => {
  const {
    agency: {
      agencyInfo: { agencyGuid },
    },
    cart: {
      reservationDetails: { orderId },
    },
  } = getState();
  try {
    if (agencyGuid && orderId) {
      if (!checkoutComplete) {
        await cartClearApi(agencyGuid, orderId);
      }
      setLocalStorage(RETIRED_CART, orderId);
    }
  } catch (error) {
    console.log(error);
  }
};

export default duplicatedSlice.reducer;
