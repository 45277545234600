import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@src/app/store';
import { getAgencyInfo } from '@src/api/agency';
import { clearCart } from '@src/components/ClearCartConfirmModal/ClearCartConfirmModalSlice';
import { logout } from '@src/features/Login/LoginSlice';
import { setLesson } from '@src/app/SystemSlice';
import { removeSessionStorage, getSessionStorage, setSessionStorage } from '@src/utils/storageUtil';

interface Terminology {
  terminologyType: string;
  singularCode: string;
  pluralCode: string;
}
interface AgencyDetailProps {
  agencyId: number;
  agencyGuid: string;
  name: string;
  applicationName: string;
  applicationId: string;
  marketName: string;
  currencyCode: string;
  locale: string;
  timeZoneId: string;
  appName: string;
  agencyTerminologies: Terminology[];
  creditCardsAllowedOnline: string[];
  echeckEnable: boolean;
  paymentCountryCode: string;
  amsAPIServerHost: string;
}

interface AgencyState {
  agencyInfo: AgencyDetailProps;
  isLoading: boolean;
  error: string;
}

const initialState: AgencyState = {
  agencyInfo: {
    agencyId: 0,
    agencyGuid: '',
    name: '',
    applicationName: '',
    applicationId: '',
    marketName: '',
    currencyCode: '',
    locale: '',
    timeZoneId: '',
    appName: '',
    agencyTerminologies: [],
    creditCardsAllowedOnline: [],
    echeckEnable: false,
    paymentCountryCode: '',
    amsAPIServerHost: '',
  },
  isLoading: true,
  error: '',
};

// Slice
const agencySlice = createSlice({
  name: 'agencyInfo',
  initialState,
  reducers: {
    getAgencyStart: state => {
      state.isLoading = true;
    },
    getAgencySuccess: (state, { payload }: PayloadAction<AgencyDetailProps>) => {
      state.agencyInfo = payload;
      state.isLoading = false;
      state.error = '';
    },
    resetAgencyInfo: state => {
      state = initialState;
    },
    getAgencyFailed: (state, { payload }: PayloadAction<string>) => {
      state.agencyInfo = initialState.agencyInfo;
      state.error = payload;
      state.isLoading = false;
    },
  },
});

export const { getAgencyStart, getAgencySuccess, resetAgencyInfo, getAgencyFailed } = agencySlice.actions;

export default agencySlice.reducer;

// Actions
export const getAgencyDetail = (agencyName: string): AppThunk => async dispatch => {
  try {
    dispatch(getAgencyStart());
    const agencyInfo = await getAgencyInfo(agencyName);
    if (!getSessionStorage('currentAgency')) {
      setSessionStorage('currentAgency', agencyInfo.name);
    } else if (getSessionStorage('currentAgency') && agencyInfo.name !== getSessionStorage('currentAgency')) {
      setSessionStorage('currentAgency', agencyInfo.name);
      removeSessionStorage('checkoutComplete');
      removeSessionStorage('preventCheckoutRefresh');
      await dispatch(clearCart(() => {}));
      await dispatch(logout());
      await dispatch(setLesson(undefined));
    }
    await dispatch(getAgencySuccess(agencyInfo));
  } catch (err) {
    dispatch(resetAgencyInfo());
    dispatch(getAgencyFailed(err ? err.toString() : 'Error'));
  }
};
