export function formatCurrency(price: number, currencyCode: string) {
  return (currencyCode === 'USD' ? '$' : currencyCode + ' ') + price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function formatToStandardWord(text: string) {
  if (text && text.length > 0) {
    text = text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase();
  }
  return text;
}

export function createLocaleRegExp(replacement: string) {
  const regExp = new RegExp('\\b(' + replacement + ')\\b', 'g');
  return regExp;
}
