/* eslint @typescript-eslint/no-explicit-any: off */
export function getLocalStorage(propName: string) {
  let propValue = null;
  if (propName) {
    const propItemValue = localStorage.getItem(propName);
    if (propItemValue) {
      try {
        propValue = JSON.parse(propItemValue);
      } catch (err) {
        propValue = propItemValue;
      }
    }
  }
  return propValue;
}

export function setLocalStorage(propName: string, propValue: any) {
  if (propName) {
    let text = propValue;
    if (propValue !== null) {
      if (typeof propValue === 'object') {
        text = JSON.stringify(propValue);
      }
    }
    localStorage.setItem(propName, text);
  }
}

export function removeLocalStorage(propName: string) {
  localStorage.removeItem(propName);
}

export function getSessionStorage(propName: string) {
  let propValue = null;
  if (propName) {
    const propItemValue = sessionStorage.getItem(propName);
    if (propItemValue) {
      try {
        propValue = JSON.parse(propItemValue);
      } catch (err) {
        propValue = propItemValue;
      }
    }
  }
  return propValue;
}

export function setSessionStorage(propName: string, propValue: any) {
  let text = propValue;
  if (typeof propValue === 'object') {
    text = JSON.stringify(propValue);
  }
  sessionStorage.setItem(propName, text);
}

export function removeSessionStorage(propName: string) {
  sessionStorage.removeItem(propName);
}
