import { createSlice } from '@reduxjs/toolkit';

interface APISlice {
  isLoading: boolean;
  requestCount: number;
}

const initialState: APISlice = {
  isLoading: false,
  requestCount: 0,
};

export const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    pushRequest: state => {
      state.requestCount += 1;
      state.isLoading = true;
    },
    popRequest: state => {
      state.requestCount -= 1;
      state.isLoading = state.requestCount !== 0;
    },
  },
});

export const { pushRequest, popRequest } = apiSlice.actions;
export default apiSlice.reducer;
