import { createSlice, ThunkDispatch } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { AppThunk } from '@src/app/store';
import difference from 'lodash/fp/difference';
import {
  TimeSlot,
  ReservationResponse,
  ReservationTimeSlot,
  reservation,
  extendReserved,
  checkUpdateCart,
  verifyReservations,
} from '@src/api/fetchLessonDetails';
import { getLessonDetails, updateCertainTimeSlotStatus, updateTimeSlotSpots } from './LessonDetailsSlice';
import { showAlertMessage } from '@src/components/AlertMessage/AlertMessageSlice';
import { openModal } from '@src/components/AlertModal/AlertModalSlice';
import { SOLD, RESERVED, OTHER_RESERVED, UNSOLD, UNAVAILABLE, RESTRICTED } from './const';
import { fixBody, releaseBody } from '@src/utils/fixBody';

interface CartState {
  multipleDaysTimeSlotListMap: DaysTimeSlotsMap[];
  reservationDetails: ReservationResponse;
  isReserving: boolean;
  cartCount: number;
  isCartLoading: boolean;
}
export interface DaysTimeSlotsMap {
  lessonDate: string;
  timeSlotRows: TimeSlotRow[];
}
export interface TimeSlotRow {
  lessonDate: string;
  instructorGuid: string;
  timeSlotIdentifier: string;
  orderLineId: number;
  price: number;
}

const initialState: CartState = {
  multipleDaysTimeSlotListMap: [],
  reservationDetails: { orderId: undefined, dailyReservationTimeSlots: [], removeOrderLineSpots: undefined },
  isReserving: false,
  cartCount: 0,
  isCartLoading: false,
};

// Slice
const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addTimeSlotMap: (state, { payload }) => {
      const currentDateTimeSlot = state.multipleDaysTimeSlotListMap.filter(item => item.lessonDate === payload.lessonDate);
      if (currentDateTimeSlot?.length) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        currentDateTimeSlot[0]?.timeSlotRows.push(payload.timeSlotRow);
      } else {
        state.multipleDaysTimeSlotListMap.push({
          lessonDate: payload.lessonDate,
          timeSlotRows: [payload.timeSlotRow],
        });
      }
      state.cartCount++;
    },

    removeTimeSlotMap: (state, { payload }) => {
      if (payload == null) {
        return;
      }
      const currentDateTimeSlot = state.multipleDaysTimeSlotListMap.filter(item => item.lessonDate === payload.lessonDate);
      const copedTimeSlots = currentDateTimeSlot[0]?.timeSlotRows?.concat();
      if (copedTimeSlots?.length) {
        // remove time slot form daily time slot list
        const slotIndex = copedTimeSlots?.findIndex(row => row.timeSlotIdentifier === payload.timeSlotIdentifier);
        copedTimeSlots.splice(slotIndex, 1);
        currentDateTimeSlot[0].timeSlotRows = copedTimeSlots;

        if (!currentDateTimeSlot[0].timeSlotRows?.length) {
          const currentDaySlotIndex = state.multipleDaysTimeSlotListMap.findIndex(timeSlot => timeSlot.lessonDate === payload.lessonDate);
          state.multipleDaysTimeSlotListMap.splice(currentDaySlotIndex, 1);
        }
      }

      state.cartCount--;
    },
    removeResponseTimeSlot: (state, { payload }) => {
      const index = state.reservationDetails.dailyReservationTimeSlots.findIndex(
        item =>
          item.lessonDate === payload?.lessonDate &&
          item.reservationTimeSlots.filter(reservation => reservation.orderLineId === payload?.orderLineId).length,
      );
      if (index !== -1) {
        state.reservationDetails.dailyReservationTimeSlots.splice(index, 1);
      }
    },
    removeDailyReservationTimeSlots: (state, { payload }) => {
      const dailyReservationTimeSlots = state.reservationDetails.dailyReservationTimeSlots.find(
        item => item.lessonDate === payload?.lessonDate,
      );
      if (dailyReservationTimeSlots) {
        const index = dailyReservationTimeSlots.reservationTimeSlots.findIndex(item => item.orderLineId === payload?.orderLineId);
        if (index !== -1) {
          dailyReservationTimeSlots.reservationTimeSlots.splice(index, 1);
        }
      }
    },
    reservationStart: state => {
      state.isReserving = true;
    },
    reservationSuccess: (state, { payload }) => {
      state.reservationDetails = payload.reservationDetails;
    },
    reservationFinalSuccess: state => {
      state.isReserving = false;
    },
    reservationFailed: state => {
      state.isReserving = false;
    },
    clearCartInfo: state => {
      state.multipleDaysTimeSlotListMap = [];
      state.reservationDetails = { orderId: undefined, dailyReservationTimeSlots: [] };
    },
    updateCartStart: state => {
      state.isCartLoading = true;
    },
    updateCartEnd: state => {
      state.isCartLoading = false;
    },
    updateSelectedTimeSlotPrice: (state, { payload }) => {
      if (payload) {
        const { price } = payload;
        state.multipleDaysTimeSlotListMap.forEach(daysTimeSlotsMap => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          daysTimeSlotsMap.timeSlotRows?.forEach(timeSlot => {
            timeSlot.price = price;
          });
        });
      }
    },
  },
});

export const {
  reservationStart,
  reservationSuccess,
  reservationFinalSuccess,
  reservationFailed,
  addTimeSlotMap,
  removeTimeSlotMap,
  removeDailyReservationTimeSlots,
  clearCartInfo,
  removeResponseTimeSlot,
  updateCartStart,
  updateCartEnd,
  updateSelectedTimeSlotPrice,
} = cartSlice.actions;

export default cartSlice.reducer;

export const clearCartAll = (): AppThunk => async (dispatch, getState) => {
  const {
    cart: { multipleDaysTimeSlotListMap },
    lessonDetails: { timeSlots },
    system: { lesson },
  } = getState();
  if (lesson) {
    const { selectedDate } = lesson;
    const currentDateTimeSlots = multipleDaysTimeSlotListMap.filter(
      multiple => multiple.lessonDate === format(selectedDate, 'yyyy-MM-dd'),
    )[0]?.timeSlotRows;

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    timeSlots &&
      currentDateTimeSlots?.map(item => {
        return dispatch(updateCertainTimeSlotStatus({ identifier: item.timeSlotIdentifier, timeSlotStatus: UNSOLD }));
      });
    dispatch(clearCartInfo());
  }
};

// Actions
export const preserved = (timeSlot: TimeSlot): AppThunk => async (dispatch, getState) => {
  const {
    agency: {
      agencyInfo: { currencyCode, agencyGuid },
    },
    system: { lesson: { lessonGuid = '', selectedDate = 0 } = {} },
    lessonDetails: { lessonDetails: { productId, name } = {} },
    cart: { reservationDetails, multipleDaysTimeSlotListMap = [] },
  } = getState();
  const formatDate = format(selectedDate, 'yyyy-MM-dd');
  try {
    dispatch(reservationStart());
    const lessonInfo = {
      currencyCode,
      productId,
      lessonName: name,
    };

    const instructor = timeSlot.instructor?.firstName + ' ' + timeSlot.instructor?.lastName;
    const newTimeSlot = {
      productId: timeSlot.instructor?.productId,
      instructor,
      startTime: timeSlot.startTime,
      endTime: timeSlot.endTime,
      currentSelect: true,
    };

    const allDailyReservationTimeSlots = [];
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    const isExisted = reservationDetails?.dailyReservationTimeSlots?.filter(item => item.lessonDate === formatDate);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    reservationDetails?.dailyReservationTimeSlots?.forEach(item => {
      if (item.lessonDate === formatDate) {
        allDailyReservationTimeSlots.push({
          lessonDate: item.lessonDate,
          reservationTimeSlots: [...item.reservationTimeSlots, newTimeSlot],
        });
      } else {
        allDailyReservationTimeSlots.push(item);
      }
    });

    if (!isExisted?.length) {
      allDailyReservationTimeSlots.push({
        lessonDate: formatDate,
        reservationTimeSlots: [newTimeSlot],
      });
    }
    const reservationData = {
      orderId: reservationDetails.orderId,
      dailyReservationTimeSlots: allDailyReservationTimeSlots,
      ...lessonInfo,
    };

    const reservationResponseDetails = await reservation(agencyGuid, lessonGuid, reservationData);
    if (!reservationResponseDetails.dailyReservationTimeSlots?.length) {
      throw Error('inner error');
    }
    syncToLocalReserved(dispatch, reservationResponseDetails, multipleDaysTimeSlotListMap, timeSlot, formatDate);
    dispatch(reservationFinalSuccess());
  } catch (error) {
    dispatch(reservationFailed());
    dispatch(
      showAlertMessage({
        type: 'error',
        content: 'An unexpected error occurred, please try again later.',
      }),
    );
  }
};

const syncToLocalReserved = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<any, any, any>,
  reservationResponseDetails: ReservationResponse,
  multipleDaysTimeSlotListMap: DaysTimeSlotsMap[],
  timeSlot: TimeSlot,
  lessonDate: string,
) => {
  if (reservationResponseDetails?.dailyReservationTimeSlots?.length) {
    let anySold = false;
    let allReserved = false;
    let anyOtherReserved = false;
    let anyUnavailable = false;
    let anyRestricted = false;
    let spots = timeSlot.spots;
    const reservationTimeSlots = [] as ReservationTimeSlot[];

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    reservationResponseDetails?.dailyReservationTimeSlots.forEach(oneDayTimeSlot => {
      reservationTimeSlots.push(...oneDayTimeSlot.reservationTimeSlots);
    });

    allReserved = reservationTimeSlots.reduce((acc: boolean, timeSlot) => acc && timeSlot.timeSlotStatus === RESERVED, true);
    anySold = reservationTimeSlots.some(ts => ts.timeSlotStatus === SOLD);
    anyOtherReserved = reservationTimeSlots.some(ts => ts.timeSlotStatus === OTHER_RESERVED);
    anyUnavailable = reservationTimeSlots.some(ts => ts.timeSlotStatus === UNAVAILABLE);
    anyRestricted = reservationTimeSlots.some(ts => ts.timeSlotStatus === RESTRICTED);
    const reservationTimeSlot = reservationTimeSlots.filter(ts => true)[0];
    const allStoreOrderIds: number[] = [];
    const allReturnIdentifiers: number[] = [];

    multipleDaysTimeSlotListMap.forEach(item => {
      item.timeSlotRows.forEach(timeSlot => {
        allStoreOrderIds.push(timeSlot.orderLineId);
      });
    });

    reservationResponseDetails.dailyReservationTimeSlots.forEach(item => {
      item.reservationTimeSlots.forEach(timeSlot => {
        timeSlot.orderLineId && allReturnIdentifiers.push(timeSlot.orderLineId);
      });
    });

    const currentOrderLineId = difference(allReturnIdentifiers, allStoreOrderIds)[0];
    reservationResponseDetails.dailyReservationTimeSlots.forEach(item => {
      const reservationTimeSlot = item.reservationTimeSlots.filter(timeSlot => {
        return timeSlot.orderLineId && timeSlot.orderLineId === currentOrderLineId;
      })[0];
      if (reservationTimeSlot) {
        spots = reservationTimeSlot.spots;
      }
    });
    if (allReserved) {
      let realNew = true;
      multipleDaysTimeSlotListMap.forEach(item => {
        realNew = realNew && !item.timeSlotRows.filter(timeSlot => timeSlot.orderLineId === currentOrderLineId).length;
      });

      realNew &&
        dispatch(
          addTimeSlotMap({
            lessonDate: lessonDate,
            timeSlotRow: {
              lessonDate: lessonDate,
              instructorGuid: timeSlot.instructor?.guid,
              timeSlotIdentifier: timeSlot.identifier,
              orderLineId: currentOrderLineId,
              price: timeSlot.price,
            },
          }),
        );

      dispatch(reservationSuccess({ reservationDetails: reservationResponseDetails, price: timeSlot.price }));
      dispatch(updateCertainTimeSlotStatus({ identifier: timeSlot.identifier, timeSlotStatus: RESERVED }));
      dispatch(updateTimeSlotSpots({ identifier: timeSlot.identifier, spots: spots }));
    } else if (anyRestricted) {
      dispatch(
        openModal({
          title: 'registration.timeSlot.select.modal.restricted.title',
          content: 'registration.timeSlot.select.modal.restricted.content',
          okText: 'common.ok',
          onCustomClick: refreshPage,
          onCloseClick: refreshPage,
        }),
      );
    } else if (anySold) {
      dispatch(
        openModal({
          title: 'registration.timeSlot.select.modal.soldOut.title',
          content: 'registration.timeSlot.select.modal.soldOut.content',
          okText: 'common.ok',
          onCustomClick: releaseBody,
          onCloseClick: releaseBody,
        }),
      );
      fixBody();
      dispatch(updateCertainTimeSlotStatus({ identifier: timeSlot.identifier, timeSlotStatus: SOLD }));
      dispatch(updateTimeSlotSpots({ identifier: timeSlot.identifier, spots: spots }));
    } else if (anyOtherReserved) {
      dispatch(
        openModal({
          title: 'registration.timeSlot.select.modal.reserved.title',
          content: 'registration.timeSlot.select.modal.reserved.content',
          okText: 'common.ok',
          onCustomClick: releaseBody,
          onCloseClick: releaseBody,
        }),
      );
      fixBody();
      dispatch(updateCertainTimeSlotStatus({ identifier: timeSlot.identifier, timeSlotStatus: RESERVED }));
      dispatch(updateTimeSlotSpots({ identifier: timeSlot.identifier, spots: spots }));
    } else if (anyUnavailable) {
      dispatch(
        openModal({
          title: 'registration.timeSlot.select.modal.unavailable.title',
          content: 'registration.timeSlot.select.modal.unavailable.content',
          okText: 'common.ok',
          onCustomClick: releaseBodyAndRefresh,
          onCloseClick: releaseBodyAndRefresh,
        }),
      );
      fixBody();
    }
  }
  function refreshPage() {
    window.location.reload();
  }
  function releaseBodyAndRefresh() {
    releaseBody();
    dispatch(getLessonDetails());
  }
};

export const cancelReserved = (timeSlot: TimeSlot): AppThunk => async (dispatch, getState) => {
  const {
    agency: {
      agencyInfo: { currencyCode, agencyGuid },
    },
    system: { lesson: { lessonGuid = '', selectedDate = 0 } = {} },
    lessonDetails: { lessonDetails: { productId, name } = {} },
    cart: { reservationDetails, multipleDaysTimeSlotListMap = [] },
  } = getState();
  const formatDate = format(selectedDate, 'yyyy-MM-dd');
  try {
    dispatch(reservationStart());
    const lessonInfo = {
      currencyCode,
      productId,
      lessonName: name,
    };

    let wannaCancelLineId;
    const currentDaysTimeSlotRows = multipleDaysTimeSlotListMap.filter(item => item.lessonDate === formatDate)[0]?.timeSlotRows;
    const currentRow = currentDaysTimeSlotRows?.filter(item => item.timeSlotIdentifier === timeSlot.identifier)[0];
    if (currentRow) {
      wannaCancelLineId = currentRow?.orderLineId;
    }

    const reservationData = {
      ...lessonInfo,
      ...reservationDetails,
      removedOrderLines: [wannaCancelLineId],
    };

    const reservationResponseDetails = await reservation(agencyGuid, lessonGuid, reservationData);
    wannaCancelLineId && syncReservedData(dispatch, multipleDaysTimeSlotListMap, wannaCancelLineId, reservationResponseDetails, formatDate);
    dispatch(reservationFinalSuccess());
  } catch (error) {
    dispatch(reservationFailed());
  }
};
export function syncReservedData(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<any, any, any>,
  multipleDaysTimeSlotListMap: DaysTimeSlotsMap[],
  currentOrderLineId: number,
  reservationResponseDetails: ReservationResponse,
  lessonDate: string,
) {
  const foundTimeSlotMap = multipleDaysTimeSlotListMap.filter(item => item.lessonDate === lessonDate)[0];
  const timeSlot = foundTimeSlotMap?.timeSlotRows.filter(tsm => tsm.orderLineId === currentOrderLineId)[0];
  if (timeSlot) {
    dispatch(
      removeTimeSlotMap({
        lessonDate,
        timeSlotIdentifier: timeSlot.timeSlotIdentifier,
      }),
    );
    dispatch(updateCertainTimeSlotStatus({ identifier: timeSlot.timeSlotIdentifier, timeSlotStatus: UNSOLD }));
    dispatch(updateTimeSlotSpots({ identifier: timeSlot.timeSlotIdentifier, spots: reservationResponseDetails.removeOrderLineSpots }));
  }
  dispatch(reservationSuccess({ reservationDetails: reservationResponseDetails }));
}

export const extendReservation = (): AppThunk => async (dispatch, getState) => {
  const {
    agency: {
      agencyInfo: { agencyGuid },
    },
    cart: {
      reservationDetails: { orderId },
    },
  } = getState();

  try {
    if (!orderId) return;
    await extendReserved(agencyGuid, orderId);
  } catch (error) {
    console.error('extend reserved failed');
  }
};

export const checkUpdateCartInfo = (): AppThunk => async (dispatch, getState) => {
  try {
    const {
      agency: {
        agencyInfo: { agencyGuid },
      },
    } = getState();
    const {
      reservationDetails: { orderId },
      multipleDaysTimeSlotListMap,
    } = getState().cart;
    dispatch(updateCartStart());
    if (orderId) {
      const updateCartResponse = await checkUpdateCart(agencyGuid, orderId);
      const { lesson } = updateCartResponse;
      dispatch(updateSelectedTimeSlotPrice(lesson));
      const { deleteTimeSlotsOrderLineIds } = updateCartResponse;
      const { deleteOrder } = updateCartResponse;
      const orderLineIds = new Set(deleteTimeSlotsOrderLineIds);
      for (const lesson of multipleDaysTimeSlotListMap) {
        const lessonDate = lesson.lessonDate;
        for (const timeSlot of lesson.timeSlotRows) {
          if (orderLineIds.has(timeSlot.orderLineId)) {
            dispatch(
              removeTimeSlotMap({
                lessonDate,
                timeSlotIdentifier: timeSlot.timeSlotIdentifier,
              }),
            );
            dispatch(
              removeDailyReservationTimeSlots({
                lessonDate,
                orderLineId: timeSlot.orderLineId,
              }),
            );
            if (deleteOrder) {
              dispatch(clearCartInfo());
            }
          }
        }
      }
    }
    dispatch(updateCartEnd());
  } catch (err) {
    dispatch(updateCartEnd());
  }
};

export const verifyCart = (handleRegistrationInfoPanelToggle: any): AppThunk => async (dispatch, getState) => {
  const {
    agency: {
      agencyInfo: { agencyGuid },
    },
    system: { lesson: { lessonGuid = '' } = {} },
    cart: {
      reservationDetails: { orderId },
    },
  } = getState();
  const params = { orderId: orderId };
  const cartVerifyResponse = await verifyReservations(agencyGuid, lessonGuid, params);
  if (cartVerifyResponse?.reservationTimeSlotList?.length) {
    const reservationTimeSlots = cartVerifyResponse.reservationTimeSlotList;
    let anyRestricted = false;
    anyRestricted = reservationTimeSlots.some(ts => ts.timeSlotStatus === RESTRICTED);
    if (anyRestricted) {
      dispatch(
        openModal({
          title: 'registration.timeSlots.verify.modal.restricted.title',
          content: 'registration.timeSlots.verify.modal.restricted.content',
          okText: 'common.ok',
          onCustomClick: refreshPage,
          onCloseClick: refreshPage,
        }),
      );
    } else {
      handleRegistrationInfoPanelToggle();
    }
  } else {
    handleRegistrationInfoPanelToggle();
  }
};

function refreshPage() {
  window.location.reload();
}
