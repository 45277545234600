/* eslint no-tabs: ["error", { allowIndentationTabs: true }] */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as RegistrationSteps from '../consts/registrationSteps';

type LessonPropsType = LessonProps | undefined;

interface SystemState {
  lesson: LessonPropsType;
  locale: string;
  regStep: number;
  maxStep: number;
}

interface LessonProps {
  lessonGuid: string;
  selectedDate: number;
}

const initialState: SystemState = {
  lesson: undefined,
  locale: 'en_US',
  regStep: RegistrationSteps.ACCOUNT_INFOMATION,
  maxStep: RegistrationSteps.ACCOUNT_INFOMATION,
};

// Slice
const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setLesson: (state: SystemState, { payload }: PayloadAction<LessonPropsType>) => {
      state.lesson = payload;
    },
    setSelectedDate: (state: SystemState, { payload }: PayloadAction<number>) => {
      state.lesson && (state.lesson.selectedDate = payload);
    },
    setLocal: (state: SystemState, { payload }: PayloadAction<string>) => {
      state.locale = payload;
    },
    setCurrentRegStep: (state: SystemState, { payload }: PayloadAction<number>) => {
      state.regStep = payload;
    },
    setMaxRegStep: (state: SystemState, { payload }: PayloadAction<number>) => {
      state.maxStep = payload;
    },
  },
});

export const { setLesson, setSelectedDate, setLocal, setCurrentRegStep, setMaxRegStep } = systemSlice.actions;

export default systemSlice.reducer;
