import React from 'react';
import useLocale from '@src/utils/useLocale';
import LogoDesk from '../Header/images/logo-mobile.png';
import './Footer.scss';

const Footer = () => {
  const { i18n } = useLocale();
  return (
    <div className='global-footer'>
      <div className='global-footer__logo'>
        <img src={LogoDesk} alt='Active Logo' />
      </div>
      <div className='global-footer__links'>
        <a href='https://www.activenetwork.com/information/terms-of-use' rel='noopener noreferrer' target='_blank'>
          {i18n('footer.link.terms.of.use')}
        </a>
        <a href='https://www.activenetwork.com/information/copyright-policy' rel='noopener noreferrer' target='_blank'>
          {i18n('footer.link.copyright.policy')}
        </a>
        <a href='https://www.activenetwork.com/information/privacy-policy' rel='noopener noreferrer' target='_blank'>
          {i18n('footer.link.your.privacy.rights')}
        </a>
        <a href='https://www.activenetwork.com/contact-us' rel='noopener noreferrer' target='_blank'>
          {i18n('footer.link.contact')}
        </a>
        <a className='optanon-show-settings'>{i18n('footer.link.cookie.settings')}</a>
      </div>
      <div className='global-footer__verbiage'>
        <span>{i18n('footer.verbiage', { year: new Date().getFullYear().toString() })}</span>
      </div>
    </div>
  );
};

export default Footer;
