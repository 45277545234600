/* eslint @typescript-eslint/no-explicit-any: off */
import { createSlice } from '@reduxjs/toolkit';
import { FormFieldDataModel } from 'form-builder/build/types';
import { AppThunk } from '@src/app/store';
import { WaiverDetails, fetchWaivers } from '@src/api/fetchWaivers';
import { saveRegForm } from '@src/api/forms';
import { fetchRegistrationFormInfo } from '@src/api/fetchRegistrationForm';
import { History } from 'history';
import { REGISTER_FOR_TYPE } from '@src/utils/constants';
import { setUserName, UserNameState } from '@src/features/Login/LoginSlice';
import { setEnterprisePersonInfo, mergeEnterprisePersonItem } from '../Attending/AttendingSlice';
import { setFieldsData, mergeParticipantsToList, setChangedParticipantsList } from '@src/features/Attending/AttendingRegistrantSlice';
import { EnterprisePerson } from '@src/api/fetchAttending';
import { PERSON_ROLE_TYPE } from '@src/consts/common';

interface YourInfoData {
  firstName: string;
  lastName: string;
  homePhone: string;
  isOver18: boolean;
}

export interface WaiverAgreement {
  agreementDate: string;
  waiverId: string;
  waiverVersion: string;
  signed: boolean;
}

interface RegistrationFormState {
  waivers: WaiverDetails[];
  formId: string;
  waiverAgreement: WaiverAgreement[];
  yourInfoData: YourInfoData;
  isLoading: boolean;
  isFormLoading: boolean;
  formViewerRendered: boolean;
}

export interface FormFieldData {
  formId: string;
  formVersion: number;
  formFields: FormFieldDataModel[];
}

export interface RegForm {
  waiverAgreement: WaiverAgreement[];
  yourInfoData: YourInfoData;
  formFieldData?: FormFieldData;
}

const initialState: RegistrationFormState = {
  waivers: [],
  formId: '',
  waiverAgreement: [],
  yourInfoData: {} as YourInfoData,
  isLoading: false,
  isFormLoading: false,
  formViewerRendered: false,
};

// Slice
const registrationFormSlice = createSlice({
  name: 'registrationForm',
  initialState,
  reducers: {
    fetchWaiversStart: state => {
      state.isLoading = true;
    },
    fetchWaiversSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.waivers = payload.waivers;
    },
    fetchWaiversFailed: state => {
      state.isLoading = false;
    },
    fetchRegFormStart: state => {
      state.isFormLoading = true;
    },
    fetchRegFormSuccess: (state, { payload }) => {
      state.isFormLoading = false;
      state.formId = payload.formId;
    },
    fetchRegFormFailed: state => {
      state.isFormLoading = false;
    },
    resetRegistration: state => {
      state.formId = initialState.formId;
      state.waivers = initialState.waivers;
      state.waiverAgreement = initialState.waiverAgreement;
      state.yourInfoData = initialState.yourInfoData;
      state.isLoading = initialState.isLoading;
      state.isFormLoading = initialState.isFormLoading;
      state.formViewerRendered = initialState.formViewerRendered;
    },
    saveRegistrationData: (state, { payload }) => {
      state.waiverAgreement = payload.waiverAgreement;
      state.yourInfoData = payload.yourInfoData;
      state.isLoading = false;
    },
    saveRegFormStart: state => {
      state.isLoading = true;
    },
    saveRegFormFailed: state => {
      state.isLoading = false;
    },
    setFormViewerRendered: state => {
      state.formViewerRendered = true;
    },
  },
});

export const {
  fetchWaiversStart,
  fetchWaiversSuccess,
  fetchWaiversFailed,
  fetchRegFormStart,
  fetchRegFormSuccess,
  fetchRegFormFailed,
  resetRegistration,
  saveRegistrationData,
  saveRegFormStart,
  saveRegFormFailed,
  setFormViewerRendered,
} = registrationFormSlice.actions;

export default registrationFormSlice.reducer;

export const resetRegistrationInfo = (parentPart: any): AppThunk => async dispatch => {
  const firstName = parentPart?.firstName;
  const lastName = parentPart?.lastName;
  const profileInfo: UserNameState = {
    firstName,
    lastName,
  };
  dispatch(setUserName(profileInfo));
};

// Actions
export const fetchWaiversAction = (): AppThunk => async (dispatch, getState) => {
  const {
    agency: {
      agencyInfo: { agencyGuid },
    },
    system: { lesson: { lessonGuid = '' } = {} },
  } = getState();

  try {
    dispatch(fetchWaiversStart());
    const waivers = await fetchWaivers(agencyGuid, lessonGuid);
    dispatch(fetchWaiversSuccess({ waivers }));
  } catch (error) {
    dispatch(fetchWaiversFailed());
  }
};

export const fetchRegFormAction = (): AppThunk => async (dispatch, getState) => {
  const {
    agency: {
      agencyInfo: { agencyGuid },
    },
    system: { lesson: { lessonGuid = '' } = {} },
  } = getState();

  try {
    dispatch(fetchRegFormStart());
    const registrationFormData = await fetchRegistrationFormInfo(agencyGuid, lessonGuid);
    const { formId } = registrationFormData;
    dispatch(fetchRegFormSuccess({ formId }));
  } catch (error) {
    dispatch(fetchRegFormFailed());
  }
};

const getParticipantByName = (firstName: String, lastName: String, personList: EnterprisePerson[]) => {
  return personList.find(
    v =>
      v.firstName.toUpperCase() === firstName.toUpperCase() &&
      v.lastName.toUpperCase() === lastName.toUpperCase() &&
      v.personRoleType !== PERSON_ROLE_TYPE.INDIVIDUAL &&
      v.personRoleType !== PERSON_ROLE_TYPE.PRIMARY_PARENT,
  );
};

export const saveRegFormAction = ({ waiverAgreement, yourInfoData, formFieldData }: RegForm, history: History): AppThunk => async (
  dispatch,
  getState,
) => {
  const {
    agency: {
      agencyInfo: { agencyGuid, applicationName },
    },
    cart: {
      reservationDetails: { orderId },
    },
    login: {
      userProfile: { personId, enterprisePersonId, email, isNewUser },
    },
    system: { lesson },
    attendingRegistrant: { registrant, registerFor, changedParticipantsList },
    attending: { enterprisePersonList },
  } = getState();

  if (!orderId || !enterprisePersonId || !lesson?.lessonGuid) return;

  const mixed: any = {};
  if (isNewUser && registrant && registerFor !== REGISTER_FOR_TYPE.PRIMARY_PARENT) {
    const person: any = getParticipantByName(registrant.firstName, registrant.lastName, enterprisePersonList);
    mixed.personId = person ? person.personId : registrant.personId;
    mixed.enterprisePersonId = person ? person.enterprisePersonId : registrant.enterprisePersonId;
  }
  const copyRegistrant: { [key: string]: any } = {
    ...registrant,
    ...mixed,
    over18: !(registerFor === REGISTER_FOR_TYPE.CHILD),
  };
  if (registerFor === REGISTER_FOR_TYPE.CHILD || registerFor === REGISTER_FOR_TYPE.PRIMARY_PARENT) {
    copyRegistrant.email = email;
  }
  const registrationForm = formFieldData;
  const { formFields = [] } = formFieldData || {};
  const registrantPart = { registrant: copyRegistrant, waiverAgreements: waiverAgreement.slice(0), registrationForm };

  const saveEnterpriseDetail = (newParticipant: EnterprisePerson, isMergeEnterpriseList: Boolean) => {
    if (newParticipant.personId && newParticipant.enterprisePersonId) {
      if (isMergeEnterpriseList) {
        const newEnterprisePersonList = mergeEnterprisePersonItem(enterprisePersonList, newParticipant, newParticipant.enterprisePersonId);
        dispatch(setEnterprisePersonInfo(newEnterprisePersonList));
      }
      const newChangedParticipantsList = mergeParticipantsToList(
        changedParticipantsList,
        newParticipant,
        newParticipant.enterprisePersonId,
      );
      dispatch(setChangedParticipantsList(newChangedParticipantsList));
    }
  };
  //parent info
  const parentPart: { [key: string]: any } = {
    ...yourInfoData,
    personId,
    enterprisePersonId,
    over18: true,
    email,
    personRoleType: REGISTER_FOR_TYPE.PRIMARY_PARENT,
  };
  const currentPersonlist = isNewUser ? changedParticipantsList : enterprisePersonList;
  const primaryParant = currentPersonlist.find(person => person.enterprisePersonId === parentPart.enterprisePersonId);
  if (personId && enterprisePersonId) {
    if (primaryParant) {
      if (registerFor === REGISTER_FOR_TYPE.PRIMARY_PARENT) {
        parentPart.firstName = primaryParant.firstName;
        parentPart.lastName = primaryParant.lastName;
        parentPart.homePhone = primaryParant?.homePhone as string;
      }
      parentPart.personId = primaryParant.personId;
      parentPart.dateOfBirth = primaryParant?.dateOfBirth as string;
    }
    if (registerFor !== REGISTER_FOR_TYPE.PRIMARY_PARENT) {
      const cloneParent = { ...parentPart };
      delete cloneParent['isOver18'];
      const newParticipant = cloneParent as EnterprisePerson;
      saveEnterpriseDetail(newParticipant, !isNewUser);
    }
  }

  const registrantFormData = {
    orderId,
    lessonGuid: lesson.lessonGuid,
    registrants: [registrantPart],
    parent: parentPart,
    agencyGuid,
  };

  const regFormData = {
    orderId,
    formData: registrantFormData,
    loginEnterprisePersonId: enterprisePersonId,
  };

  try {
    dispatch(saveRegFormStart());
    await saveRegForm(regFormData, agencyGuid);
    dispatch(setFieldsData(formFields));
    dispatch(saveRegistrationData({ waiverAgreement, yourInfoData }));
    dispatch(resetRegistrationInfo(parentPart));
    history.push(`/${applicationName}/checkout`);
  } catch (error) {
    dispatch(saveRegFormFailed());
  }
};
