/* eslint @typescript-eslint/no-explicit-any: off */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormFieldDataModel } from 'form-builder/build/types';
import { EnterprisePerson } from '@src/api/fetchAttending';
import { isNotEmpty } from '@src/utils/validators';

interface AttendingRegistrantState {
  registerFor: string;
  registrant: EnterprisePerson | null;
  waiverProps: any;
  regFormFields: FormFieldDataModel[];
  selectedEnterprisePersonId: string;
  changedParticipantsList: EnterprisePerson[];
}

const initialState: AttendingRegistrantState = {
  registerFor: '',
  registrant: null,
  waiverProps: null,
  regFormFields: [],
  selectedEnterprisePersonId: '',
  changedParticipantsList: [],
};

export const mergeParticipantsToList = (
  enterprisePersonsList: EnterprisePerson[],
  enterprisePerson: EnterprisePerson,
  currentEnterprisePersonId: string,
) => {
  const participantsList: EnterprisePerson[] = enterprisePersonsList?.concat([]) || [];
  if (isNotEmpty(enterprisePerson)) {
    if (isNotEmpty(currentEnterprisePersonId) && currentEnterprisePersonId !== 'create') {
      const mappingIndex = participantsList.findIndex(participant => participant.enterprisePersonId === currentEnterprisePersonId);
      if (mappingIndex > -1) {
        participantsList[mappingIndex] = enterprisePerson;
      } else {
        participantsList.unshift(enterprisePerson);
      }
    } else {
      participantsList.unshift(enterprisePerson);
    }
  }
  return participantsList;
};

// Slice
const attendingSlice = createSlice({
  name: 'attendingRegistrant',
  initialState,
  reducers: {
    setRegistrant: (state: AttendingRegistrantState, { payload }: PayloadAction<EnterprisePerson>) => {
      state.registrant = payload;
    },
    setRegisterFor: (state: AttendingRegistrantState, { payload }: PayloadAction<string>) => {
      state.registerFor = payload;
    },
    setWaiverProps: (state: AttendingRegistrantState, { payload }: PayloadAction<any>) => {
      state.waiverProps = payload;
    },
    setFieldsData: (state: AttendingRegistrantState, { payload }: PayloadAction<FormFieldDataModel[]>) => {
      state.regFormFields = payload;
    },
    setSelectedEnterprisePersonId: (state: AttendingRegistrantState, { payload }: PayloadAction<string>) => {
      state.selectedEnterprisePersonId = payload;
    },
    setChangedParticipantsList: (state: AttendingRegistrantState, { payload }: PayloadAction<EnterprisePerson[]>) => {
      state.changedParticipantsList = payload || [];
    },
    clearFieldsData: (state: AttendingRegistrantState) => {
      state.regFormFields = [];
    },
    resetRegistrant: (state: AttendingRegistrantState) => {
      state.registrant = initialState.registrant;
      state.registerFor = initialState.registerFor;
      state.waiverProps = initialState.waiverProps;
      state.selectedEnterprisePersonId = '';
      state.changedParticipantsList = [];
      state.regFormFields = [];
    },
  },
});

export const {
  setRegisterFor,
  setRegistrant,
  setWaiverProps,
  setFieldsData,
  resetRegistrant,
  clearFieldsData,
  setChangedParticipantsList,
  setSelectedEnterprisePersonId,
} = attendingSlice.actions;
export default attendingSlice.reducer;
