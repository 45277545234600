import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import './Spinner.scss';

interface Props {
  type?: string;
  showMask?: boolean;
  wrapperClassName?: string;
}

interface BodyStylesType {
  overflowX: string;
  overflowY: string;
  paddingRight: string;
}

const Spinner: React.FC<Props> = ({ type, showMask, wrapperClassName }: Props) => {
  const paddingLeftRef = useRef<string>();
  const bodyOverflowXRef = useRef<string>();
  const bodyOverflowYRef = useRef<string>();

  const fixBody = (spinnerDom: HTMLElement) => {
    const bodyStyles = (document.body && document.body.style) || ({} as BodyStylesType);
    let scrollbarWidth = 0;
    if (document.documentElement) {
      scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    }
    bodyOverflowXRef.current = bodyStyles.overflowX;
    bodyOverflowYRef.current = bodyStyles.overflowY;
    paddingLeftRef.current = bodyStyles.paddingRight;

    bodyStyles.overflowX = 'hidden';
    bodyStyles.overflowY = 'hidden';

    /* istanbul ignore else */
    if (scrollbarWidth > 0 && document.body) {
      const { paddingRight } = getComputedStyle(document.body);
      bodyStyles.paddingRight = `${parseInt(paddingRight, 10) + scrollbarWidth}px`;
    }
    spinnerDom.style.cssText = `left: calc((100% - ${bodyStyles.paddingRight}) / 2)`;
  };

  const unfixBody = (spinnerDom: HTMLElement) => {
    const bodyStyles = (document.body && document.body.style) || ({} as BodyStylesType);
    bodyStyles.overflowX = bodyOverflowXRef.current || '';
    bodyStyles.overflowY = bodyOverflowYRef.current || '';
    bodyStyles.paddingRight = paddingLeftRef.current || '';

    spinnerDom.style.cssText = '';
  };

  useEffect(() => {
    const spinnerDom = document.querySelector('.spinner') as HTMLElement;
    fixBody(spinnerDom);
    return () => {
      unfixBody(spinnerDom);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={wrapperClassName}>
      <div className='reserve-cui-spinner'>
        <div className='spinner'>
          <div className={clsx('spinner__pic', `spinner__pic--${type}`)} />
        </div>
        {showMask ? <div className='spinner-mask' /> : null}
        {type === 'transparent' && <div className='spinner-mask spinner-mask--transparent' />}
      </div>
    </div>
  );
};

export default Spinner;
