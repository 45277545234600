import axios, { AxiosError } from 'axios';
import { config } from '../config';
import store from '../app/store';
import { pushRequest, popRequest } from './apiSlice';
import { showAlertMessage } from '../components/AlertMessage/AlertMessageSlice';

const { env } = window;

const api = axios.create({
  baseURL: config.getResourceAPI(env),
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

api.defaults.headers.common['Cache-Control'] = 'no-cache, no-store, must-revalidate';
api.defaults.headers.common.Expires = 0;

api.interceptors.request.use(
  config => {
    store.dispatch(pushRequest());
    const token = sessionStorage.getItem('token');
    if (token && config && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    store.dispatch(popRequest());
    return Promise.reject(error);
  },
);

export function showError() {
  store.dispatch(
    showAlertMessage({
      type: 'error',
      content: 'An unexpected error occurred, please try again later.',
    }),
  );
}

export function responseError(error: AxiosError) {
  if (error.response == null || error.response.status == null) {
    // add request aborted evaluation to fix firefox error toast shown issue
    if (error.message.indexOf('Request aborted') < 0) {
      showError();
    }
    return Promise.reject(error);
  }

  const errorCodeReg = new RegExp('^(4|5)\\d{2}$');
  const errorStatusString = error.response.status.toString();
  if (errorCodeReg.test(errorStatusString)) {
    showError();
  }
  return Promise.reject(error);
}

api.interceptors.response.use(
  response => {
    store.dispatch(popRequest());
    return { ...response, data: response.data.data };
  },
  error => {
    store.dispatch(popRequest());
    return responseError(error);
  },
);
export default api;
