import useLocale from '@active/react-utils/i18n/useLocale';
import store from '@src/app/store';
import { isNotEmpty } from './validators';
import { formatToStandardWord, createLocaleRegExp } from './formatUtil';

export function getTerminologiesText(text: string) {
  const {
    agency: {
      agencyInfo: { agencyTerminologies },
    },
  } = store.getState();
  if (text && isNotEmpty(agencyTerminologies)) {
    agencyTerminologies.forEach(item => {
      const pluralCode = escape(item.pluralCode || '');
      const singularCode = escape(item.singularCode || '');
      const singularText = item.terminologyType.toLowerCase();
      const pluralText = singularText + 's';
      const singularUpperText = item.terminologyType.toUpperCase();
      const pluralUpperText = singularUpperText + 'S';
      const singularStandardText = formatToStandardWord(item.terminologyType);
      const pluralStandardText = singularStandardText + 's';
      if (pluralCode) {
        text = text.replace(createLocaleRegExp(pluralUpperText), pluralCode.toUpperCase());
        text = text.replace(createLocaleRegExp(pluralText), pluralCode.toLowerCase());
        text = text.replace(createLocaleRegExp(pluralStandardText), formatToStandardWord(pluralCode));
      }
      if (singularCode) {
        text = text.replace(createLocaleRegExp(singularUpperText), singularCode.toUpperCase());
        text = text.replace(createLocaleRegExp(singularText), singularCode.toLowerCase());
        text = text.replace(createLocaleRegExp(singularStandardText), formatToStandardWord(singularCode));
      }
    });
    text = unescape(text);
  }
  return text;
}

export default function useLocal() {
  const { i18n } = useLocale();
  const i18nLocale = (
    id: string,
    params?:
      | Array<string | number>
      | {
          [key: string]: string;
        },
  ) => {
    let text = i18n(id, params);
    text = getTerminologiesText(text);
    return text;
  };
  return { i18n: i18nLocale };
}
