import api from './api';

export interface Appearance {
  headerDesktopImageUrl: string;
  headerMobileImageUrl: string;
}

export async function fetchAppearance(agencyGuid: string) {
  const url = `/agencies/${agencyGuid}/appearance`;
  const { data } = await api.get<Appearance>(url);
  return data;
}
