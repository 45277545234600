/* eslint @typescript-eslint/no-explicit-any: off */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@src/app/store';
import { FormBuilderToken, getFormBuilderToken } from '@src/api/fetchFormBuilderToken';

export interface FormManagementState {
  loading: boolean;
  token?: FormBuilderToken;
}

const initialState: FormManagementState = {
  loading: true,
  token: undefined,
};

const formManagementSlice = createSlice({
  name: 'formManagement',
  initialState,
  reducers: {
    resetState: () => initialState,
    setToken: (state, { payload }: PayloadAction<FormBuilderToken>) => {
      state.token = payload;
    },
    finishLoading: state => {
      state.loading = false;
    },
  },
});

export const { resetState, setToken, finishLoading } = formManagementSlice.actions;
export default formManagementSlice.reducer;

export const fetchFormBuilderToken = (): AppThunk => async (dispatch, getState) => {
  const {
    agency: {
      agencyInfo: { agencyGuid },
    },
  } = getState();
  const formBuilderToken = await getFormBuilderToken(agencyGuid);
  dispatch(setToken(formBuilderToken));
};
