import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@src/app/store';
import { getCheckoutToken, removeCartItem } from '@src/api/fetchCheckout';
import { getTokenConfig } from '@src/utils/checkoutConfig';
import { parse } from 'date-fns';
import { syncReservedData } from '../LessonDetails/CartSlice';
import { History } from 'history';
import { DailyReservationTimeSlotDetails } from '@src/api/fetchLessonDetails';
import { setCurrentRegStep, setMaxRegStep, setSelectedDate } from '@src/app/SystemSlice';
import * as RegistrationSteps from '@src/consts/registrationSteps';
import { clearFieldsData } from '@src/features/Attending/AttendingRegistrantSlice';
interface CheckoutState {
  widgetToken: string;
  isLoading: boolean;
}

const initialState: CheckoutState = {
  widgetToken: '',
  isLoading: false,
};

interface OrderEditAction {
  checkoutOrderIdentifier: number;
  checkoutOrderLineIdentifier: number;
  type: string;
  refreshData: () => void;
}

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    startRequest: (state: CheckoutState) => {
      state.isLoading = true;
    },
    getTokenSuccess: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.widgetToken = payload;
    },
    endRequest: (state: CheckoutState) => {
      state.isLoading = false;
    },
    processFailures: (state: CheckoutState) => {
      state.isLoading = false;
    },
    resetState: () => initialState,
  },
});

export const { startRequest, processFailures, getTokenSuccess, endRequest, resetState } = checkoutSlice.actions;
export default checkoutSlice.reducer;

export const fetchCheckoutToken = (): AppThunk => async (dispatch, getState) => {
  const {
    agency: {
      agencyInfo: { agencyGuid, agencyId, creditCardsAllowedOnline = [], echeckEnable },
    },
    cart: {
      reservationDetails: { orderId },
    },
    login: {
      userProfile: { signedWaiverIds = [] },
    },
  } = getState();

  const checkoutParam = getTokenConfig(String(orderId), agencyId, creditCardsAllowedOnline, echeckEnable, signedWaiverIds);

  try {
    dispatch(startRequest());
    const { widgetToken } = await getCheckoutToken(checkoutParam, agencyGuid);
    dispatch(getTokenSuccess(widgetToken));
  } catch (error) {
    dispatch(processFailures());
  }
};

export const deleteCartItem = (editAction: OrderEditAction, history: History): AppThunk => async (dispatch, getState) => {
  const {
    agency: {
      agencyInfo: { agencyGuid, currencyCode, applicationName },
    },
    cart: { reservationDetails, multipleDaysTimeSlotListMap = [] },
    system: { lesson: { lessonGuid = '' } = {} },
  } = getState();

  const reservations = transfromReservation(reservationDetails.dailyReservationTimeSlots);
  const deleteReservation = reservations.find(reservation => reservation.orderLineId === editAction.checkoutOrderLineIdentifier);
  const removeParam = {
    lessonGuid,
    currencyCode,
    ...reservationDetails,
    removedOrderLines: [editAction.checkoutOrderLineIdentifier],
  };

  try {
    dispatch(startRequest());
    const reservationResponseDetails = await removeCartItem(removeParam, agencyGuid);
    syncReservedData(
      dispatch,
      multipleDaysTimeSlotListMap,
      editAction.checkoutOrderLineIdentifier,
      reservationResponseDetails,
      deleteReservation?.lessonDate as string,
    );
    if (
      !reservationResponseDetails ||
      !reservationResponseDetails.dailyReservationTimeSlots ||
      reservationResponseDetails.dailyReservationTimeSlots.every(
        dailyReservation =>
          !dailyReservation || !dailyReservation.reservationTimeSlots || dailyReservation.reservationTimeSlots.length === 0,
      )
    ) {
      dispatch(clearFieldsData());
      dispatch(setCurrentRegStep(RegistrationSteps.WHO_IS_ATTENDING));
      dispatch(setMaxRegStep(RegistrationSteps.WHO_IS_ATTENDING));
      history.replace(`/${applicationName}/lessons`);
    }
    dispatch(endRequest());
  } catch (error) {
    dispatch(processFailures());
  }
};

export const editCartItem = (editAction: OrderEditAction, history: History): AppThunk => (dispatch, getState) => {
  const {
    agency: {
      agencyInfo: { applicationName },
    },
    cart: {
      reservationDetails: { dailyReservationTimeSlots },
    },
  } = getState();

  const reservations = transfromReservation(dailyReservationTimeSlots);
  const editItem = reservations.find(item => item.orderLineId === editAction.checkoutOrderLineIdentifier);
  if (editItem) {
    dispatch(setSelectedDate(parse(editItem.lessonDate, 'yyyy-MM-dd', new Date()).getTime()));
    dispatch(setCurrentRegStep(RegistrationSteps.WHO_IS_ATTENDING));
    dispatch(setMaxRegStep(RegistrationSteps.WHO_IS_ATTENDING));
  }
  history.push(`/${applicationName}/lessonDetails`);
};

const transfromReservation = (dailyReservationTimeSlots: DailyReservationTimeSlotDetails[]) => {
  let reservedItems = [] as { lessonDate: string; orderLineId: number }[];
  if (dailyReservationTimeSlots && dailyReservationTimeSlots.length) {
    reservedItems = dailyReservationTimeSlots.reduce((accumulator, currentValue) => {
      const items = currentValue.reservationTimeSlots.map(timeSlot => ({
        lessonDate: currentValue.lessonDate,
        orderLineId: timeSlot.orderLineId as number,
      }));
      return [...accumulator, ...items];
    }, [] as { lessonDate: string; orderLineId: number }[]);
  }
  return reservedItems;
};
