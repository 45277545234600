import { configureStore, getDefaultMiddleware, ThunkAction, Action } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import { reduxBatch } from '@manaflair/redux-batch';

import history from './history';
import rootReducer, { RootState as RootReducerState } from './rootReducer';

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  whitelist: ['login', 'cart', 'system', 'attendingRegistrant', 'agency'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false,
  }),
  routerMiddleware(history),
];

const preloadedState = {};

const store = configureStore({
  reducer: persistedReducer,
  middleware,
  preloadedState,
  enhancers: [reduxBatch],
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export default store;

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type RootState = RootReducerState;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootReducerState, unknown, Action<string>>;
