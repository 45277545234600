import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@src/app/store';
import { fetchAppearance, Appearance } from '@src/api/appearance';

interface BannerState {
  isLoading: boolean;
  headerDesktopImageUrl?: string;
  headerMobileImageUrl?: string;
}

const initialState: BannerState = {
  isLoading: false,
};

export const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    getAppearanceStart: state => {
      state.isLoading = true;
    },
    getAppearanceSuccess: (state: BannerState, { payload }: PayloadAction<Appearance>) => {
      if (payload) {
        const { headerDesktopImageUrl, headerMobileImageUrl } = payload;
        state.headerDesktopImageUrl = headerDesktopImageUrl;
        state.headerMobileImageUrl = headerMobileImageUrl;
      }
      state.isLoading = false;
    },
    getAppearanceFailed: (state: BannerState, { payload }: PayloadAction<String>) => {
      state.isLoading = false;
    },
  },
});

const { getAppearanceStart, getAppearanceSuccess, getAppearanceFailed } = bannerSlice.actions;

export const getAppearance = (agencyGuid: string): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(getAppearanceStart());
    const appearance = await fetchAppearance(agencyGuid);
    dispatch(getAppearanceSuccess(appearance));
  } catch (err) {
    dispatch(getAppearanceFailed(err ? err.toString() : 'Error'));
  }
};

export default bannerSlice.reducer;
