import React, { useState, useEffect, Children } from 'react';
import { useSelector } from 'react-redux';
import { Provider as LocaleProvider } from '@active/react-utils/i18n/LocaleContext';
import { RootState } from '@src/app/rootReducer';
import DEFAULT_MESSAGES from '../../i18n/en_US.json';

interface ProviderProps {
  children: React.Component | JSX.Element;
}

const I18nProvider = ({ children }: ProviderProps) => {
  const [messages, setMessages] = useState(DEFAULT_MESSAGES);
  const { locale } = useSelector((state: RootState) => state.system);
  useEffect(() => {
    import(`../../i18n/${locale}.json`).then(messages => {
      setMessages(messages);
    });
  }, [locale]);

  return <LocaleProvider locales={messages}>{Children.only(children)}</LocaleProvider>;
};

export default I18nProvider;
