/* eslint @typescript-eslint/no-explicit-any: off */
import api from './api';
import { LESSON_FTECH_PAGE } from '../utils/constants';
import { isNotEmpty } from '@src/utils/validators';

export interface Lesson {
  lessonGuid: string;
  lessonName: string;
  online: boolean;
  lessonPrice: number;
  lessonDate: LessonDate;
  ageRestriction: AgeRestriction | null;
  complexInfo: ComplexInfo;
  instructors: Instructor[] | null;
  description: string;
}

export interface Instructor {
  firstName: string;
  lastName: string;
}
export interface ComplexInfo {
  complexName: string;
  address: ComplextAddress;
}

export interface DateRange {
  startDate: string;
  endDate: string;
}
export interface DateStatus {
  date: string;
  status: number;
}
export interface ComplextAddress {
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  stateProvince: string | null;
  postalCode: string | null;
  countryCode: string | null;
}
export interface AgeRestriction {
  minAge: number;
  maxAge: number;
}
export interface LessonDate {
  startDate: string;
  endDate: string;
}

export async function fetchLessons(agencyGuid: string, page: number, keyword: string) {
  if (isNotEmpty(keyword)) {
    keyword = encodeURIComponent(keyword.trim());
  }
  const url = `/agencies/${agencyGuid}/lessons/list?size=${LESSON_FTECH_PAGE}&page=${page}&keyword=${keyword}`;
  // const url = '/lesson';
  const { data } = await api.get<Lesson[]>(url);
  return data;
}

export async function fetchLessonDateRange(lessonGuid: string) {
  // const url = '/api/lessonCalender/${lessonGuid}/dateRange';
  const url = '/dateRange';
  const { data } = await api.get<DateRange>(url);
  return data;
}

export async function fetchDateRangeStatus(agencyGuid: string, lessonGuid: string, startDate: string, endDate: string) {
  const url = `/agencies/${agencyGuid}/lessonCalender/${lessonGuid}/dateRangeStatus?from=${startDate}&to=${endDate}`;
  // const url = '/dateRangeStatus';
  const { data } = await api.get<DateStatus[]>(url);
  return data;
}
