export const LESSON_FTECH_PAGE = 21;
export const TIME_SLOT_FETCH_PAGE_SIZE = 10;
export const REGISTER_FOR_TYPE = {
  CHILD: 'CHILD',
  OTHERS: 'OTHERS',
  PRIMARY_PARENT: 'PRIMARY_PARENT',
};
export const CHECKOUT_PAGE_PATH = /^(\/)?[^/]+\/checkout$/i;
export const DUPLICATED_PAGE_PATH = /^(\/)?[^/]+\/duplicated$/i;
export const RETIRED_CART = 'retiredCart';
export const REGISTRATION_FORM_ENABLE = true;
