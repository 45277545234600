import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addDays, format, parse } from 'date-fns';
import { AppThunk } from '@src/app/store';
import { fetchDateRangeStatus } from '@src/api/fetchLessons';

interface WeeklyCalendarState {
  isLoading: boolean;
  selectedDate?: number;
  startDateOfWeek?: number;
  disabledDates?: Array<number>;
}

const initialState: WeeklyCalendarState = {
  isLoading: false,
};

export const weeklyCalendarSlice = createSlice({
  name: 'weeklyCalendar',
  initialState,
  reducers: {
    setSelectedDate: (state, { payload }: PayloadAction<number>) => {
      state.selectedDate = payload;
    },
    setStartDateOfWeek: (state, { payload }: PayloadAction<number>) => {
      state.startDateOfWeek = payload;
    },
    setDisableDatesStart: state => {
      state.isLoading = true;
    },
    setDisableDatesSuccess: (state, { payload }: PayloadAction<Array<number>>) => {
      state.disabledDates = payload;
      state.isLoading = false;
    },
    setDisableDatesFailed: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
    },
  },
});

const {
  setSelectedDate,
  setStartDateOfWeek,
  setDisableDatesStart,
  setDisableDatesSuccess,
  setDisableDatesFailed,
} = weeklyCalendarSlice.actions;

export { setSelectedDate, setStartDateOfWeek };

export default weeklyCalendarSlice.reducer;

export const setDisabledDates = (startDateOfWeek: number): AppThunk => async (dispatch, getState) => {
  try {
    const {
      agency: {
        agencyInfo: { agencyGuid },
      },
      system: { lesson },
    } = getState();

    dispatch(setDisableDatesStart());

    if (!agencyGuid || !lesson?.lessonGuid) {
      dispatch(setDisableDatesFailed('No lesson'));
      return;
    }

    const endDateOfWeek = addDays(startDateOfWeek, 6);
    const disabledDates = await fetchDateRangeStatus(
      agencyGuid,
      lesson.lessonGuid,
      format(startDateOfWeek, 'yyyy-MM-dd'),
      format(endDateOfWeek, 'yyyy-MM-dd'),
    );

    dispatch(
      setDisableDatesSuccess(disabledDates.filter(ds => ds.status === 0).map(ds => parse(ds.date, 'yyyy-MM-dd', new Date()).getTime())),
    );
  } catch (err) {
    dispatch(setDisableDatesFailed(err ? err.toString() : 'Error'));
  }
};
