import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import AlertModal from '@src/components/AlertModal';
import debounce from 'lodash/fp/debounce';
import { openModal } from '@src/components/AlertModal/AlertModalSlice';
import { clearCart } from '@src/components/ClearCartConfirmModal/ClearCartConfirmModalSlice';
import { clearCart as cartClearApi } from '@src/api/fetchLessonDetails';
import { destroy } from '@src/api/fetchLoginInfo';
import { extendReservation } from '@src/features/LessonDetails/CartSlice';
import { logout, refreshJWTToken } from '@src/features/Login/LoginSlice';
import { getSessionStorage } from '@src/utils/storageUtil';
import { SESSION_TIME_OUT, LOGOUT_TIME_OUT, KEEP_ALIVE_INTERVAL, EXTEND_SESSION_INTERVAL } from '@src/consts/common';
interface IdleMonitorProps {
  isLogined: boolean;
  startShopping: boolean;
  agencyGuid: string;
}

const IdleMonitor = ({ isLogined, startShopping, agencyGuid }: IdleMonitorProps) => {
  const dispatch = useDispatch();
  const idleTimerRef = useRef<NodeJS.Timeout>();
  const idleTimerCounterRef = useRef<number>(KEEP_ALIVE_INTERVAL);
  const extendTimerCounterRef = useRef<number>(KEEP_ALIVE_INTERVAL);
  const events = ['mousemove', 'click', 'keypress', 'touchstart', 'touchmove'];
  const { pathname } = window.location;
  const applicationName = pathname.split('/').length > 1 ? pathname.split('/')[1] : '';
  const redirectToDefaultPage = () => {
    sessionStorage.removeItem('persist:root');
    sessionStorage.removeItem('token');
    window.location.href = `/${applicationName}/lessons`;
  };

  const getCartItemsFromStorage = () => {
    return (
      getSessionStorage('persist:root') &&
      JSON.parse(getSessionStorage('persist:root')?.cart)?.reservationDetails?.dailyReservationTimeSlots
    );
  };

  const getOrderIdFromStorage = () => {
    return getSessionStorage('persist:root') && JSON.parse(getSessionStorage('persist:root')?.cart)?.reservationDetails?.orderId;
  };

  const getLoginStatusFromStorage = () => {
    return getSessionStorage('persist:root') && JSON.parse(getSessionStorage('persist:root')?.login)?.isLogined;
  };

  const getLoginToken = () => {
    return sessionStorage.getItem('token');
  };

  const setIdleTime = (newTimer: number) => {
    idleTimerCounterRef.current = newTimer;
  };

  const getIdleTime = () => {
    return idleTimerCounterRef.current;
  };

  const resetIdleTime = debounce(200, () => {
    setIdleTime(KEEP_ALIVE_INTERVAL);
    checkIdleTimerAlive();
  });

  const clearSession = () => {
    const currentPath = window.location.pathname;
    const currentRouter = currentPath.split('/').length > 1 ? currentPath.substring(currentPath.lastIndexOf('/') + 1) : '';
    if (currentRouter === 'checkout') {
      if (sessionStorage.getItem('checkoutComplete') !== 'true') {
        const orderId = getOrderIdFromStorage();
        if (agencyGuid && orderId) {
          cartClearApi(agencyGuid, orderId);
        }

        if (getLoginStatusFromStorage()) {
          destroy();
        }
        showWarning();
      }
      stopIdleCheck();
    } else {
      if (getCartItemsFromStorage()?.length > 0) {
        dispatch(clearCart(() => {}));
        logoutProcess();
        clearCommonStorage();
      }
    }
  };

  const showWarning = () => {
    dispatch(
      openModal({
        title: 'common.note',
        content: 'idle.logout.message',
        okText: 'common.ok',
        onCustomClick: redirectToDefaultPage,
        onCloseClick: redirectToDefaultPage,
      }),
    );
  };

  const logoutProcess = () => {
    if (getLoginToken() && getLoginStatusFromStorage()) {
      dispatch(logout());
      clearCommonStorage();
    }
  };

  const clearCommonStorage = () => {
    sessionStorage.removeItem('persist:root');
    showWarning();
    stopIdleCheck();
  };

  const refreshToken = () => {
    if (getLoginToken() && getLoginStatusFromStorage()) {
      dispatch(refreshJWTToken());
    }
  };

  const expandReserved = () => {
    if (getOrderIdFromStorage()) {
      dispatch(extendReservation());
    }
  };

  const checkIdleTime = () => {
    if (extendTimerCounterRef.current % EXTEND_SESSION_INTERVAL === 0) {
      expandReserved();
      refreshToken();
    }

    if (getIdleTime() >= SESSION_TIME_OUT) {
      clearSession();
    }

    if (getIdleTime() >= LOGOUT_TIME_OUT) {
      logoutProcess();
    }

    setIdleTime(getIdleTime() + KEEP_ALIVE_INTERVAL);
    extendTimerCounterRef.current += KEEP_ALIVE_INTERVAL;
  };

  const checkIdleTimerAlive = () => {
    const haveItemInCart = getOrderIdFromStorage() && getCartItemsFromStorage()?.length > 0;
    const haveLogined = getLoginStatusFromStorage();
    if (!idleTimerRef.current && (haveItemInCart || haveLogined)) {
      startIdleCheck();
    }
  };

  const stopIdleCheck = () => {
    idleTimerRef.current && clearInterval(idleTimerRef.current);
    idleTimerRef.current = undefined;
  };

  const startIdleCheck = () => {
    stopIdleCheck();
    idleTimerRef.current = setInterval(checkIdleTime, KEEP_ALIVE_INTERVAL);
    extendTimerCounterRef.current = KEEP_ALIVE_INTERVAL;
  };

  useEffect(() => {
    for (const e in events) {
      window.addEventListener(events[e], resetIdleTime);
    }

    return () => {
      for (const e in events) {
        window.removeEventListener(events[e], resetIdleTime);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ((isLogined || startShopping) && !idleTimerRef.current) {
      startIdleCheck();
    } else if (!isLogined && !startShopping && idleTimerRef.current) {
      stopIdleCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogined, startShopping]);

  return <AlertModal customName='idle-time-note' />;
};

export default IdleMonitor;
