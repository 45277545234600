import api from './api';
import { ComplexInfo } from './fetchLessons';

export interface Instructor {
  guid: string;
  firstName: string;
  lastName: string;
  productId: string;
}

export interface TimeSlot {
  identifier: string;
  startTime: string;
  endTime: string;
  price: number;
  instructor: Instructor;
  timeSlotStatus: string;
  durationInMinus: number;
  spots: number;
  productId: string;
}
export interface AgeRestriction {
  minAge: number;
  maxAge: number;
}

export interface TimeStamp {
  epochSecond: number;
  nano: number;
}

export interface LessonDetails {
  guid: string;
  name: string;
  mode: boolean;
  description: string;
  startDate: string;
  endDate: string;
  ageRestriction: AgeRestriction;
  instructors: Instructor[];
  timeSlots: TimeSlot[];
  complex: ComplexInfo;
  productId: string;
}
export interface ReservationTimeSlot {
  orderLineId?: number;
  instructor?: string;
  productId: string;
  startTime: string;
  endTime: string;
  spots: number;
  timeSlotStatus?: string;
  removedOrderLines?: number[];
}

export interface DailyReservationTimeSlotDetails {
  lessonDate: string;
  reservationTimeSlots: ReservationTimeSlot[];
}
export interface ReservationResponse {
  orderId?: number;
  dailyReservationTimeSlots: DailyReservationTimeSlotDetails[];
  removeOrderLineSpots?: number;
}

export interface CartVerifyResponse {
  orderId?: number;
  reservationTimeSlotList: ReservationTimeSlot[];
}
export async function fetchLessonDetails(agencyGuid: string, lessonGuid: string, selectedDate: string, instructorId: string) {
  const instructorIdParam = instructorId === 'ALL' ? '' : instructorId;
  const url = `/agencies/${agencyGuid}/lessons/${lessonGuid}?date=${selectedDate}&instructorId=${instructorIdParam}`;
  const { data } = await api.get<LessonDetails>(url);
  return data;
}

export async function reservation(agencyGuid: string, lessonGuid: string, paramData: {}) {
  const url = `/agencies/${agencyGuid}/lessons/${lessonGuid}/reservation`;
  const { data } = await api.post<ReservationResponse>(url, paramData);
  return data;
}

export async function clearCart(agencyGuid: string, orderId: number) {
  const url = `/agencies/${agencyGuid}/lessons/reservation/${orderId}`;
  await api.delete(url);
}

export async function checkUpdateCart(agencyGuid: string, orderId: number) {
  const url = `/agencies/${agencyGuid}/lessons/reservation/${orderId}/checkUpdateCart`;
  const { data } = await api.post(url);
  return data;
}

export async function extendReserved(agencyGuid: string, orderId: number) {
  const url = `/agencies/${agencyGuid}/lessons/reservation/${orderId}/touch`;
  await api.put<ReservationResponse>(url);
}

export async function verifyReservations(agencyGuid: string, lessonGuid: string, paramData: {}) {
  const url = `/agencies/${agencyGuid}/lessons/${lessonGuid}/verifyReservations`;
  const { data } = await api.post<CartVerifyResponse>(url, paramData);
  return data;
}
