import { config } from '@src/config';

export function getTokenConfig(
  orderId: string,
  agencyId: number,
  onlineCardTypes: string[],
  echeckEnable: boolean,
  signedWaiverIds: Array<number | string>,
) {
  const { env } = window;
  const param = {
    widget: 'CHECKOUT',
    domain: config.getDomain(env),
  };

  const customizedData = {
    checkout_order_identifier: String(orderId),
    agency_id: agencyId,
    access_permission: 'OWNER',
    available_card_types: onlineCardTypes,
    payment_methods: echeckEnable ? ['ECHECK'] : [],
    enable_cvv: true,
    enable_three_domain_secure: false,
    enable_loading_saved_payment_account: true,
    enable_saving_payment_account: true,
    enable_prefilling_billing_info: true,
    enable_echeck_form: true,
    waiver_ids_to_be_linked: signedWaiverIds,
    enable_coupon: true,
    apple_pay_card_types: onlineCardTypes,
    google_pay_card_types: onlineCardTypes,
  };

  const checkoutParam = { ...param, customizedData: JSON.stringify(customizedData) };
  return checkoutParam;
}

export interface OrderLineAction {
  identifier: string;
  editable: boolean;
  deletable: boolean;
}

export interface CheckoutEditAction {
  checkoutOrderIdentifier: string;
  checkoutOrderLineIdentifier: string;
  type: string;
  refreshData: () => void;
}
interface CheckoutRenderConfig {
  jwtToken: string;
  locale: string;
  orderId: string;
  checkoutDomain?: string;
  checkoutCallback: () => void;
  handleOrderReviewCallback: (action: CheckoutEditAction) => void;
  orderReviewHandleableConfig: OrderLineAction[];
  defaultBillingContact?: {};
  orderReceiptAndAccountParams?: {};
  checkoutConfirmationPageTitle: string;
  checkoutConfirmationPageSubTitle: string;
  startOver: () => void;
  paymentCountryCode: string;
  amsAPIServerHost: string;
}

export function getCheckoutRenderConfig(initConfig: CheckoutRenderConfig) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const config: { [key: string]: any } = {
    /**
     * Common Properties
     */
    locale: initConfig.locale,
    jwt: initConfig.jwtToken,
    amsAPIServerHost: initConfig.amsAPIServerHost,
    paymentCountryCode: initConfig.paymentCountryCode,
    checkoutOrderIdentifier: initConfig.orderId,
    widgetAPIServerHost: initConfig?.checkoutDomain,
    onUserLoginAction: function () {},
    handleInvalidTransactionAction: initConfig.startOver,
    checkoutCallback: initConfig.checkoutCallback,
    checkoutConfig: {
      /**
       * Order Review Section Properties
       */
      showOrderReviewSection: true,
      handleOrderReviewCallback: initConfig.handleOrderReviewCallback,
      // orderReviewActionText: 'Test title',
      // orderReviewActionCallback: (obj: {}) => {
      //   console.log(JSON.stringify(obj));
      // },
      orderReviewHandleableConfig: initConfig.orderReviewHandleableConfig,
      /**
       * Billing Information Section Properties
       */
      showEmailField: true,
      /**
       * Payment Method Section Properties
       */
      collectBillingContactConfigs: [
        {
          paymentMethodType: 'CREDIT_CARD',
          collectBillingContact: true,
        },
        {
          paymentMethodType: 'ECHECK',
          collectBillingContact: true,
        },
        {
          paymentMethodType: 'CHECK',
          collectBillingContact: false,
        },
        {
          paymentMethodType: 'CASH',
          collectBillingContact: false,
        },
        {
          paymentMethodType: 'APPLEPAY',
          collectBillingContact: true,
        },
        {
          paymentMethodType: 'GOOGLEPAY',
          collectBillingContact: true,
        },
      ],
      /**
       * Order Confirmation Section Properties
       */
      isShowConfirmationSection: true,
      // agencyInfo: {},
      confirmationMessage: {
        title: initConfig.checkoutConfirmationPageTitle,
        subTitle: initConfig.checkoutConfirmationPageSubTitle,
      },
    },
  };
  if (initConfig.defaultBillingContact) {
    config.checkoutConfig.defaultBillingContact = initConfig.defaultBillingContact;
  }

  if (initConfig.orderReceiptAndAccountParams) {
    config.orderReceiptAndAccountParams = initConfig.orderReceiptAndAccountParams;
  }
  return config;
}
