import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from '@src/app/store';
import history from '@src/app/history';
import { Provider } from 'react-redux';
import { ThemeProvider, createMuiTheme, StylesProvider } from '@material-ui/core/styles';
import Routes from './routes';
import LocalProvider from '@src/components/LocalProvider/LocalProvider';
import 'antd/dist/antd.css';
import '@active/react-ui/css/react-ui.css';
import './index.scss';

const theme = createMuiTheme({
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  typography: {
    fontFamily: 'ProximaNova',
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        borderRadius: '5px',
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.03)',
        backgroundColor: '#ffffff',
      },
    },
  },
});

ReactDOM.render(
  <StylesProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppContainer>
            <LocalProvider>
              <Routes history={history} />
            </LocalProvider>
          </AppContainer>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </StylesProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
