import { connectRouter } from 'connected-react-router';
import { combineReducers } from '@reduxjs/toolkit';

import agencyReducer from '@src/components/Agency/AgencySlice';
import alertMessageReducer from '@src/components/AlertMessage/AlertMessageSlice';
import weeklyCalendarReducer from '@src/components/WeeklyCalendar/WeeklyCalendarSlice';
import lessonReducer from '@src/features/Lessons/LessonsSlice';
import lessonRangePickerReducer from '@src/components/LessonRangePicker/LessonRangePickerSlice';
import lessonDetailsReducer from '@src/features/LessonDetails/LessonDetailsSlice';
import cartReducer from '@src/features/LessonDetails/CartSlice';
import systemReducer from './SystemSlice';
import loginReducer from '@src/features/Login/LoginSlice';
import AttendingReducer from '@src/features/Attending/AttendingSlice';
import AttendingRegistrantReducer from '@src/features/Attending/AttendingRegistrantSlice';
import history from '@src/app/history';
import apiReducer from '@src/api/apiSlice';
import bannerReducer from '@src/components/Banner/BannerSlice';
import clearCartConfirmModalReducer from '@src/components/ClearCartConfirmModal/ClearCartConfirmModalSlice';
import registrationReducer from '@src/features/RegistrationForm/RegistrationSlice';
import alertModalReducer from '@src/components/AlertModal/AlertModalSlice';
import checkoutReducer from '@src/features/Checkout/CheckoutSlice';
import duplicatedReducer from '@src/features/Duplicated/DuplicatedSlice';
import formManagementReducer from '@src/features/RegistrationForm/FormManagementSlice';

const rootReducer = combineReducers({
  alertMessage: alertMessageReducer,
  weeklyCalendar: weeklyCalendarReducer,
  router: connectRouter(history),
  agency: agencyReducer,
  lesson: lessonReducer,
  lessonDateRange: lessonRangePickerReducer,
  api: apiReducer,
  lessonDetails: lessonDetailsReducer,
  banner: bannerReducer,
  clearCartConfirmModal: clearCartConfirmModalReducer,
  login: loginReducer,
  attending: AttendingReducer,
  attendingRegistrant: AttendingRegistrantReducer,
  system: systemReducer,
  cart: cartReducer,
  registration: registrationReducer,
  alertModal: alertModalReducer,
  checkout: checkoutReducer,
  duplicated: duplicatedReducer,
  formManagement: formManagementReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
