export const fixBody = () => {
  document.body.style.top = `-${window.pageYOffset}px`;
  document.body.style.position = 'fixed';
};

export const releaseBody = () => {
  document.body.style.position = 'static';
  const scrollY = document.body.style.top;
  document.body.style.top = '';
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
};
