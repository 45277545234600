import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ShowPayload {
  type: string;
  content: string;
  multiple?: boolean;
}

interface Message {
  id: number;
  type: string;
  content: string;
}

interface AlertMessageState {
  isShow: boolean;
  messages: Message[];
}

const initialState: AlertMessageState = {
  isShow: false,
  messages: [],
};

export const alertMessageSlice = createSlice({
  name: 'alertMessage',
  initialState,
  reducers: {
    showAlertMessage: (state, { payload }: PayloadAction<ShowPayload>) => {
      state.isShow = true;

      const id = +new Date();
      const { type, content, multiple } = payload;
      state.messages = multiple ? [{ id, type, content }, ...state.messages] : [{ id, type, content }];
    },
    closeAlertMessage: (state, { payload }: PayloadAction<number>) => {
      state.messages = state.messages.filter(item => item.id !== payload);
    },
  },
});

export const { showAlertMessage, closeAlertMessage } = alertMessageSlice.actions;

export default alertMessageSlice.reducer;
