import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@src/app/store';
import { clearCart as clearCartAPI } from '@src/api/fetchLessonDetails';
import { clearCartAll } from '@src/features/LessonDetails/CartSlice';
import { clearFieldsData } from '@src/features/Attending/AttendingRegistrantSlice';

interface ClearCartConfirmModalState {
  isLoading: boolean;
  error: string | null;
}

const initialState: ClearCartConfirmModalState = {
  isLoading: false,
  error: null,
};

export const clearCartConfirmModalSlice = createSlice({
  name: 'clearCartConfirmModal',
  initialState,
  reducers: {
    clearCartStart: state => {
      state.isLoading = true;
    },
    clearCartSuccess: state => {
      state.isLoading = false;
    },
    clearCartFailed: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

const { clearCartStart, clearCartSuccess, clearCartFailed } = clearCartConfirmModalSlice.actions;

export const clearCart = (handleConfirm: () => void): AppThunk => async (dispatch, getState) => {
  try {
    const {
      agency: {
        agencyInfo: { agencyGuid },
      },
    } = getState();
    const {
      reservationDetails: { orderId },
    } = getState().cart;
    dispatch(clearCartStart());
    if (orderId) {
      await clearCartAPI(agencyGuid, orderId);
      dispatch(clearCartSuccess());
      handleConfirm();
      dispatch(clearCartAll());
      dispatch(clearFieldsData());
    } else {
      dispatch(clearCartFailed('No orderId'));
    }
  } catch (err) {
    dispatch(clearCartFailed(err ? err.toString() : 'Error'));
  }
};

export default clearCartConfirmModalSlice.reducer;
