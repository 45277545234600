import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@src/app/store';
import { UserProfile, fetchUserProfile, fetchJwtToken, fetchAnonymousToken, destroy, refreshToken } from '@src/api/fetchLoginInfo';
import { setCurrentRegStep, setMaxRegStep } from '@src/app/SystemSlice';
import { resetRegistrant } from '@src/features/Attending/AttendingRegistrantSlice';
import { removeSessionStorage } from '@src/utils/storageUtil';
import * as RegistrationSteps from '../../consts/registrationSteps';
import { setEnterprisePersonInfo } from '@src/features/Attending/AttendingSlice';
import { v4 as uuidv4 } from 'uuid';

interface LoginState {
  isLogined: boolean;
  userProfile: UserProfile;
  isLoading: boolean;
}

export interface UserNameState {
  firstName: string;
  lastName: string;
}

const initialState: LoginState = {
  isLogined: false,
  userProfile: {},
  isLoading: false,
};

// Slice
const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    startRequest: (state: LoginState) => {
      state.isLoading = true;
    },
    getUserProfileSuccess: (state: LoginState, { payload }: PayloadAction<UserProfile>) => {
      state.isLogined = true;
      state.userProfile = payload;
      state.isLoading = false;
    },
    changeUser: (state: LoginState) => {
      state.isLogined = false;
      state.userProfile = {};
      state.isLoading = false;
      sessionStorage.removeItem('token');
    },
    mergeUserProfile: (state: LoginState, { payload }: PayloadAction<UserProfile>) => {
      state.userProfile = { ...state.userProfile, ...payload };
    },
    putNewUser: {
      reducer(state: LoginState, action: PayloadAction<UserProfile>) {
        state.isLogined = true;
        state.userProfile = action.payload;
        state.isLoading = false;
      },
      prepare(userProfile: UserProfile) {
        return { payload: { ...userProfile, personId: uuidv4(), enterprisePersonId: uuidv4(), isNewUser: true } };
      },
    },
    processFailures: (state: LoginState) => {
      state.isLoading = false;
    },
    setUserName: (state: LoginState, { payload }: PayloadAction<UserNameState>) => {
      state.isLogined = true;
      state.userProfile = { ...state.userProfile, ...payload };
      state.isLoading = false;
    },
  },
});

export const {
  getUserProfileSuccess,
  changeUser,
  putNewUser,
  mergeUserProfile,
  startRequest,
  processFailures,
  setUserName,
} = loginSlice.actions;
export default loginSlice.reducer;
export const getUserProfile = (dispatchNextStep?: () => void): AppThunk => async (dispatch, getState) => {
  const {
    agency: {
      agencyInfo: { agencyGuid },
    },
  } = getState();

  try {
    dispatch(startRequest());
    const { token } = await fetchJwtToken();
    sessionStorage.setItem('token', token);
    const userProfile = await fetchUserProfile(agencyGuid);
    if (dispatchNextStep) {
      dispatchNextStep();
    } else {
      dispatch(setMaxRegStep(RegistrationSteps.WHO_IS_ATTENDING));
      dispatch(setCurrentRegStep(RegistrationSteps.WHO_IS_ATTENDING));
    }
    dispatch(getUserProfileSuccess(userProfile));
  } catch {
    dispatch(processFailures());
  }
};

export const loginAsNewUser = (
  {
    email,
    signedWaiverIds,
  }: {
    email: string;
    signedWaiverIds?: Array<number | string>;
  },
  dispatchNextStep?: () => void,
): AppThunk => async dispatch => {
  try {
    dispatch(startRequest());
    const { token } = await fetchAnonymousToken(email);
    sessionStorage.setItem('token', token);
    if (dispatchNextStep) {
      dispatchNextStep();
    }
    dispatch(putNewUser({ email, signedWaiverIds, isChangeLocalProfile: true }));
  } catch {
    dispatch(processFailures());
  }
};

export const logout = (): AppThunk => async (dispatch, getState) => {
  try {
    const {
      login: {
        userProfile: { isNewUser },
      },
    } = getState();

    if (!isNewUser) {
      dispatch(startRequest());
      await destroy();
    }
    dispatch(changeUser());
    removeSessionStorage('token');
    dispatch(resetRegistrant());
    dispatch(setEnterprisePersonInfo([]));
    dispatch(setMaxRegStep(RegistrationSteps.ACCOUNT_INFOMATION));
    dispatch(setCurrentRegStep(RegistrationSteps.ACCOUNT_INFOMATION));
  } catch {
    dispatch(processFailures());
  }
};

export const refreshJWTToken = (): AppThunk => async () => {
  try {
    const { token } = await refreshToken();
    sessionStorage.setItem('token', token);
  } catch (err) {
    console.error('refresh token failed');
  }
};
