import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@src/app/rootReducer';
import { getMessageIcon } from '../../utils/message';
import { closeAlertMessage } from './AlertMessageSlice';
import './AlertMessage.scss';

interface ToastProps {
  type: string;
  content: string;
  closeError: (id: number) => void;
  id: number;
}

const Toast: React.FC<ToastProps> = ({ type, content, closeError, id }: ToastProps) => {
  useEffect(() => {
    setTimeout(() => {
      closeError(id);
    }, 7000);
  }, [closeError, id]);

  return (
    <div data-test-id='alert-message' className={clsx('alert-message', `alert-message--${type}`)}>
      <i data-test-id='alert-message__icon' className={`alert-message__icon ${getMessageIcon(type)}`} />
      <p data-test-id='alert-message__content' className='alert-message__content'>
        {content}
      </p>
    </div>
  );
};

const AlertMessage: React.FC<unknown> = () => {
  const { isShow, messages } = useSelector((state: RootState) => state.alertMessage);
  const dispatch = useDispatch();

  return isShow ? (
    <div data-test-id='alert-message-wrap' className={clsx('alert-message-wrap')}>
      {messages.map(({ id, type, content }) => (
        <Toast key={id} id={id} type={type} content={content} closeError={id => dispatch(closeAlertMessage(id))} />
      ))}
    </div>
  ) : null;
};

export default AlertMessage;
