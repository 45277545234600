/* eslint no-tabs: ["error", { allowIndentationTabs: true }] */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@src/app/store';
import { Lesson, fetchLessons } from '@src/api/fetchLessons';
import { LESSON_FTECH_PAGE } from '../../utils/constants';

interface LessonState {
  isLoading: boolean;
  error?: string;
  selectCount: number;
  lessons: Lesson[];
  hasMore: boolean;
}

const initialState: LessonState = {
  isLoading: true,
  error: '',
  lessons: [],
  selectCount: 0,
  hasMore: false,
};

function startLoading(state: LessonState) {
  state.isLoading = true;
}

function loadingFailed(state: LessonState, { payload }: PayloadAction<string>) {
  // state.isLoading = false;
  state.error = payload;
}

// Slice
const sessionSlice = createSlice({
  name: 'lessons',
  initialState,
  reducers: {
    getLessonStart: startLoading,
    getLessonSuccess: (state: LessonState, { payload }: PayloadAction<Lesson[]>) => {
      state.hasMore = payload && payload.length >= LESSON_FTECH_PAGE;
      state.lessons = state.lessons.concat(payload);
      state.isLoading = false;
      state.error = '';
    },
    getLessonFailed: loadingFailed,
    resetLesson: () => {
      return initialState;
    },
  },
});

export const { getLessonStart, getLessonSuccess, getLessonFailed, resetLesson } = sessionSlice.actions;

export default sessionSlice.reducer;

// Actions
export const getLessons = (agencyGuid: string, page: number, keyword: string): AppThunk => async dispatch => {
  try {
    dispatch(getLessonStart());
    const lessons = await fetchLessons(agencyGuid, page, keyword);
    dispatch(getLessonSuccess(lessons));
  } catch (err) {
    dispatch(getLessonFailed(err ? err.toString() : 'Error'));
  }
};

export const getLessonsWithInfiniteLoader = (agencyGuid: string, page: number, keyword: string): AppThunk => async dispatch => {
  try {
    const lessons = await fetchLessons(agencyGuid, page, keyword);
    dispatch(getLessonSuccess(lessons));
  } catch (err) {
    dispatch(getLessonFailed(err ? err.toString() : 'Error'));
  }
};
