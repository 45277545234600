/* eslint no-tabs: ["error", { allowIndentationTabs: true }] */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@src/app/store';
import { DateStatus, fetchDateRangeStatus } from '@src/api/fetchLessons';

interface LessonRangePickerState {
  isLoading: boolean;
  error: string | null;
  dateRangeStatusList: DateStatus[];
}

const initialState: LessonRangePickerState = {
  isLoading: false,
  error: '',
  dateRangeStatusList: [],
};

function startLoading(state: LessonRangePickerState) {
  state.isLoading = true;
}

function loadingFailed(state: LessonRangePickerState, { payload }: PayloadAction<string>) {
  state.isLoading = false;
  state.error = payload;
  state.dateRangeStatusList = [];
}

// Slice
const lessonRangePickerSlice = createSlice({
  name: 'lessonRangePicker',
  initialState,
  reducers: {
    getLessonRangeStatusStart: startLoading,
    getLessonRangeStatusFailed: loadingFailed,
    getLessonDateRangeStatus: (state: LessonRangePickerState, { payload }: PayloadAction<DateStatus[]>) => {
      state.dateRangeStatusList = payload;
      state.isLoading = false;
      state.error = '';
    },
    resetLessonRangePicker: () => {
      return initialState;
    },
  },
});

export const {
  getLessonRangeStatusStart,
  getLessonRangeStatusFailed,
  resetLessonRangePicker,
  getLessonDateRangeStatus,
} = lessonRangePickerSlice.actions;

export default lessonRangePickerSlice.reducer;

export const getDateRangeStatus = (lessonGuid: string, startDate: string, endDate: string): AppThunk => async (dispatch, getState) => {
  try {
    const {
      agency: {
        agencyInfo: { agencyGuid },
      },
    } = getState();
    dispatch(getLessonRangeStatusStart());
    const dateRangePickList = await fetchDateRangeStatus(agencyGuid, lessonGuid, startDate, endDate);
    dispatch(getLessonDateRangeStatus(dateRangePickList));
  } catch (err) {
    dispatch(getLessonRangeStatusFailed(err ? err.toString() : 'Error'));
  }
};
