/* eslint @typescript-eslint/no-explicit-any: off */

import api from './api';

interface Terminology {
  terminologyType: string;
  singularCode: string;
  pluralCode: string;
}
export interface AgencyDetail {
  agencyId: number;
  agencyGuid: string;
  name: string;
  applicationName: string;
  applicationId: string;
  marketName: string;
  currencyCode: string;
  locale: string;
  timeZoneId: string;
  appName: string;
  creditCardsAllowedOnline: string[];
  echeckEnable: boolean;
  agencyTerminologies: Terminology[];
  paymentCountryCode: string;
  amsAPIServerHost: string;
}

export async function getAgencyInfo(agencyName: string) {
  const url = `/agency?agencyName=${agencyName}`;
  const { data } = await api.get<AgencyDetail>(url);
  return data;
}
