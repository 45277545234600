import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@src/app/store';
import { EnterprisePerson, fetchEnterprisePersons, fetchEnterprisePersonsFromCCM } from '@src/api/fetchAttending';
import { isNotEmpty } from '@src/utils/validators';
import { PERSON_ROLE_TYPE } from '@src/consts/common';
import { mergeUserProfile } from '@src/features/Login/LoginSlice';

interface EnterprisePersonState {
  isLoading: boolean;
  error: string | null;
  enterprisePersonList: EnterprisePerson[];
}

const initialState: EnterprisePersonState = {
  isLoading: false,
  error: '',
  enterprisePersonList: [],
};

const mergeEnterprisePersonList = (enterprisePersonsList: EnterprisePerson[], changedParticipantsList: EnterprisePerson[]) => {
  let newEnterprisePersonList: EnterprisePerson[] = enterprisePersonsList?.concat([]) || [];
  if (isNotEmpty(changedParticipantsList)) {
    if (newEnterprisePersonList.length > 0) {
      changedParticipantsList.forEach(item => {
        const mappingIndex = newEnterprisePersonList.findIndex(participant => participant.enterprisePersonId === item.enterprisePersonId);
        if (mappingIndex > -1) {
          newEnterprisePersonList[mappingIndex] = item;
        } else {
          newEnterprisePersonList.unshift(item);
        }
      });
    } else {
      newEnterprisePersonList = changedParticipantsList;
    }
  }
  return newEnterprisePersonList;
};

const getPrimaryParent = (list: EnterprisePerson[]) => {
  return list.find(v => v.personRoleType === PERSON_ROLE_TYPE.INDIVIDUAL || v.personRoleType === PERSON_ROLE_TYPE.PRIMARY_PARENT);
};

// Slice
const attendingSlice = createSlice({
  name: 'attending',
  initialState,

  reducers: {
    getEnterprisePersonStart: (state: EnterprisePersonState) => {
      state.isLoading = true;
    },
    getEnterprisePersonSuccess: (state: EnterprisePersonState, { payload }: PayloadAction<EnterprisePerson[]>) => {
      state.error = '';
      state.isLoading = false;
      state.enterprisePersonList = payload;
    },
    getEnterprisePersonFailed: (state: EnterprisePersonState, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isLoading = false;
      state.enterprisePersonList = [];
    },
    setEnterprisePersonInfo: (state: EnterprisePersonState, { payload }: PayloadAction<EnterprisePerson[]>) => {
      state.enterprisePersonList = payload;
    },
  },
});

export const {
  getEnterprisePersonStart,
  getEnterprisePersonSuccess,
  getEnterprisePersonFailed,
  setEnterprisePersonInfo,
} = attendingSlice.actions;
export default attendingSlice.reducer;

export const getEnterprisePersonList = (): AppThunk => async (dispatch, getState) => {
  const {
    agency: {
      agencyInfo: { agencyGuid },
    },
    login: { userProfile },
    attendingRegistrant: { changedParticipantsList },
  } = getState();

  try {
    dispatch(getEnterprisePersonStart());
    let enterprisePersons: EnterprisePerson[] = [];
    if (userProfile.isNewUser) {
      enterprisePersons = await fetchEnterprisePersonsFromCCM(agencyGuid, userProfile.email || '');
      const user = getPrimaryParent(enterprisePersons);
      if (user && userProfile.isChangeLocalProfile) {
        dispatch(mergeUserProfile({ ...user, isChangeLocalProfile: false, firstName: '', lastName: '' }));
      }
    } else {
      enterprisePersons = await fetchEnterprisePersons(agencyGuid, userProfile.enterprisePersonId || '');
      enterprisePersons = mergeEnterprisePersonList(enterprisePersons, changedParticipantsList || []);
    }
    dispatch(getEnterprisePersonSuccess(enterprisePersons));
  } catch (err) {
    dispatch(getEnterprisePersonFailed(err ? err.toString() : 'Error'));
  }
};

export const mergeEnterprisePersonItem = (
  enterprisePersonList: EnterprisePerson[],
  enterprisePerson: EnterprisePerson,
  currentEnterprisePersonId: string,
) => {
  const newEnterprisePersonList: EnterprisePerson[] = enterprisePersonList?.concat([]) || [];
  if (isNotEmpty(enterprisePerson)) {
    if (isNotEmpty(currentEnterprisePersonId) && currentEnterprisePersonId !== 'create') {
      const personIndex = newEnterprisePersonList.findIndex(item => item.enterprisePersonId === currentEnterprisePersonId);
      if (personIndex > -1) {
        newEnterprisePersonList[personIndex] = enterprisePerson;
      } else {
        newEnterprisePersonList.unshift(enterprisePerson);
      }
    } else {
      newEnterprisePersonList.unshift(enterprisePerson);
    }
  }
  return newEnterprisePersonList;
};
