import api from './api';

export interface RegistrationForm {
  formId: string;
}

export async function fetchRegistrationFormInfo(agencyGuid: string, lessonGuid: string) {
  const url = `/agencies/${agencyGuid}/forms/${lessonGuid}/regFormInfo`;
  const { data } = await api.get<RegistrationForm>(url);
  return data;
}
