/* eslint @typescript-eslint/no-explicit-any: off */

import * as React from 'react';

interface AsyncComponentState {
  Component: any;
}

export default function asyncComponent(getComponent: any): any {
  class AsyncComponent extends React.Component<{}, AsyncComponentState> {
    constructor(props: any) {
      super(props);

      this.state = {
        Component: null,
      };
    }

    async componentDidMount() {
      const { default: Component } = await getComponent();

      this.setState({
        Component,
      });
    }

    render() {
      const { Component } = this.state;

      return Component ? <Component {...this.props} /> : null;
    }
  }
  return AsyncComponent;
}

/** *
 * should use hooks to create a async component, but now it does not work, need more effort to spike
 * so keep it now temporarily
 */

// import React, { useState, useEffect } from 'react';

// export default (importComponent: any) => {
//   const AsyncComponent = (props: any) => {
//     const [component, setComponent] = useState<any>();
//     useEffect(() => {
//       const getComponent = async () => {
//         const { default: newComponent } = await importComponent();
//         setComponent(newComponent);
//       };
//       getComponent();
//     });

//     const DynamicComponent = component;
//     return DynamicComponent ? <DynamicComponent {...props} /> : null;
//   };
//   return AsyncComponent;
// };
// AsyncComponent.tsx
